import {createStore, compose, applyMiddleware} from "redux";
import {persistStore, persistReducer} from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import rootReducer from "./rootReducer";
import rootSaga from './rootSaga'
import storage from "redux-persist/lib/storage";

import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewareEnhancers = applyMiddleware(sagaMiddleware);
const composedEnhancers = composeWithDevTools(middlewareEnhancers);

let intraStore = createStore(
    persistedReducer,
    composedEnhancers,
);

sagaMiddleware.run(rootSaga);

export const store = intraStore;
export const persistor = persistStore(store);
