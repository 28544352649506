import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  IMAGE_UPLOAD,
  IMAGE_UPLOAD_ERROR,
  IMAGE_UPLOAD_SUCCESS,
} from "./constants";

let CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/dskqukkzq/upload";

const endPoints = {
  UPLOAD: "",
};

// function that makes the api request and returns a Promise for response
function uploadImage(data) {
  return axios.post(
    `${CLOUDINARY_URL}`,
    {
        file: data,
        upload_preset: "imbis73r",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
// worker saga: makes the api call when watcher saga sees the action
function* workerSaga(action) {
  try {
    let data = {
      file: base64Img,
      upload_preset: "imbis73r",
    };
    let base64Img = `data:image/jpg;base64,${action.payload.base64}`;
    const response = yield call(uploadImage, base64Img);
    yield put({ type: IMAGE_UPLOAD_SUCCESS, payload: response });
    //NavigatorService.navigate('Preview')
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: IMAGE_UPLOAD_ERROR, error });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* uploadSagas() {
  yield all([takeLatest(IMAGE_UPLOAD, workerSaga)]);
}
