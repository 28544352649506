import {
    USER_LOGIN,
    USER_LOGIN_ERROR,
    USER_LOGIN_SUCCESS,
    SET_LANGUAGE,
} from './constants';

import produce from 'immer';

const initialState = {
    user: null,
    fetching: false,
    error: null,
    language: "CN",
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case SET_LANGUAGE:
                draft.language = action.payload;
            case USER_LOGIN:
                draft.fetching = true;
                draft.error = null;
                return;
            case USER_LOGIN_SUCCESS:
                draft.user = action.payload;
                draft.fetching = false;
                return;
            case USER_LOGIN_ERROR:
                draft.fetching = false;
                draft.error = action.error;
                return;
            }
    });

export default reducer;
