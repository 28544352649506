import {all, call, put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import {
    USER_LOGIN,
    USER_LOGIN_ERROR,
    USER_LOGIN_SUCCESS,
} from './constants';


const endPoints = {
    login: '',
};


// function that makes the api request and returns a Promise for response
function loginUser(data) {
    return 'ok'
}
// worker saga: makes the api call when watcher saga sees the action
function* workerSaga(action) {
    try {
        const response = yield call(loginUser, action.payload);
        yield put({type: USER_LOGIN_SUCCESS, payload: response });
    } catch (error) {
        console.log(error);
        // dispatch a failure action to the store with the error
        yield put({type: USER_LOGIN_ERROR, error});
    }
}


// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* loginSagas() {
    yield all([
        takeLatest(USER_LOGIN, workerSaga),
    ]);
}
