import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { BackButton, Background } from "../../Components";
import useWindowDimensions from "../../hooks/WindowDimensions";
import { useHistory, useParams, useLocation } from "react-router";
import { ArrowDownward } from "@material-ui/icons";
import i18n from "../../translation/i18n";
import noScroll from "no-scroll";

/**
 * This is an example of a container component.
 *
 * This screen displays a little help message and informations about a fake user.
 * Feel free to remove it.
 */

const GameScreen = () => {
  const { height, width } = useWindowDimensions();
  const history = useHistory();
  const unit = width / 10;
  const hunit = height / 10;

  return (
    <Background>
      <BackButton onClick={() => history.goBack()} />
      <iframe style={{display: "flex", flex: 1, height: height}} src="https://guccibloom.gucci.com/" />
{/*       <WebView
        source={{ uri:  }}
        automaticallyAdjustContentInsets
        nativeConfig
        //incognito
        //source={{ uri: 'https://google.com' }}
        style={{
          flex: 1,
          height: height,
          backgroundColor: "transparent",
          width: width,
        }}
      /> */}
    </Background>
  );
};

const mapStateToProps = (state) => ({
  imageUrl: state.image.imageUrl,
});

export default connect(mapStateToProps, null)(GameScreen);
