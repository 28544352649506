import {
    IMAGE_UPLOAD,
    IMAGE_UPLOAD_ERROR,
    IMAGE_UPLOAD_SUCCESS,
    IMAGE_SET
} from './constants';

import produce from 'immer';

const initialState = {
    imageUrl: null,
    fetching: false,
    error: null,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case IMAGE_UPLOAD:
                draft.imageUrl = null;
                draft.fetching = true;
                draft.error = null;
                return;
            case IMAGE_UPLOAD_SUCCESS:
                draft.imageUrl = action.payload.data.url;
                draft.fetching = false;
                return;
            case IMAGE_UPLOAD_ERROR:
                draft.fetching = false;
                draft.error = action.error;
                return;
            }
    });

export default reducer;
