import {
    IMAGE_SET,
    IMAGE_UPLOAD,
} from "./constants";

export const setImage = (payload) => {
    return {
        type: IMAGE_SET,
        payload
    };
};

export const imageUpload = (payload) => {
    return {
        type: IMAGE_UPLOAD,
        payload
    };
};
