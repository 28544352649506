import React, { useState, useEffect, Component } from "react";
import styled from "styled-components";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import i18n from "../translation/i18n";
import useWindowDimensions from "../hooks/WindowDimensions";
import { isMobile, isTablet } from "react-device-detect"

export const Title = styled.p`
  color: ${(props) => props.color || "white"};
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2) / 50}px`};
  text-align: center;
  font-family: GucciSans;
  font-weight: bold;
  margin: 0px;
`;


export const Button = styled.button`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  z-index: 5;
`;

export const ButtonM = styled.div`
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  z-index: 5;
  padding: 2px;
  margin: 0;
`;

export const ButtonVert = styled.button`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
`;

export const BackButton = ({ ...props }) => {
  const { height, width } = useWindowDimensions();
  return(
  <button
    style={{
      display: "flex",
      flexDirection: "row",
      position: "absolute",
      top: height * 0.03,
      left: 20,
      zIndex: 20,
      outline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
      outline: "none",
      border: "none",
    }}
    onClick={props.onClick}
  >
    <ArrowBackIos
      fontSize={"small"}
      style={{
        textAlign: "center",
        marginRight: 15,
        color: "white",
        alignSelf: "center",
      }}
    />
    <Title color={props.color}>{i18n.t("back")}</Title>
  </button>
)};

export const SkipButton = ({ ...props }) => {
  const { height, width } = useWindowDimensions();
  return (
  <button
    style={{
      display: "flex",
      flexDirection: "row",
      position: "absolute",
      top: height * 0.03,
      right: 20,
      zIndex: 10,
      outline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
      outline: "none",
      border: "none",
    }}
    onClick={props.onClick}
  >
    <Title color={props.color}>
      {props.next ? i18n.t("next") : i18n.t("menu")}
    </Title>
    <ArrowForwardIos
      fontSize={"small"}
      style={{
        textAlign: "center",
        alignSelf: "center",
        marginLeft: 15,
        color: "white",
      }}
    />
  </button>
)};

export const Background = styled.div`
  display: flex;
  flex: 1;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  align-items: center;
  background-color: #102c37;
  justify-content: center;
`;

export const ImageB = styled.img`
  height: ${(props) => props.theme.mobile ? `${props.theme.height / 8}px` : `${props.theme.width / 6}px`};
  width: ${(props) => props.theme.mobile ? `${props.theme.height / 8}px` : `${props.theme.width / 6}px`};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
`;
export const ButtonI = styled.button`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  z-index: 5;
`;
export const ImageButton = ({ ...props }) => {
  return (
    <Button style={{ zIndex: 10 }} onClick={props.onClick}>
      <ImageB
        src={props.source}
        marginBottom={props.marginBottom}
        marginLeft={props.marginLeft}
        marginRight={props.marginRight}
        marginTop={props.marginTop}
      />
    </Button>
  );
};

export const LogoTop = styled.img`
  position: absolute;
  max-height: ${(props) => `${props.theme.width / 5}px`};
  left: ${(props) => `0px`};
  top: 0;
  z-index: 1;
`;

export const LogoBottom = styled.img`
  position: absolute;
  max-height: ${(props) => `${props.theme.width / 5}px`};
  left: ${(props) => `${props.theme.width / 5}px`};
  bottom: 0;
  z-index: 1;
`;

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  top: 0;
  left: 0;
`;

export const WrapperCursor = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  top: 0;
  left: 0;
`;

export const WrapperFull = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex: 1;
  max-width: ${(props) => `${props.theme.width}px`};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

export const Model = styled.div`
  display: flex;
  width: ${(props) => `${props.theme.width}px`};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 130px;
  flex-direction: column;
  z-index: 5;
`;

export const Subtitle = styled.p`
  color: white;
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2) / 50}px`};
  font-style: italic;
  font-family: GucciSans;
`;

export const SubtitleAdj = styled.p`
  color: #c58273;
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2) / 80}px`};
  font-style: italic;
  font-family: GucciSans;
`;

export const Description = styled.p`
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height* 1.2 ) / 100}px`};
  color: white;
  text-align: center;
  font-family: GucciSans;
  max-width: 1000px;
`;

export const BackgroundI = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  align-items: center;
  background-color: #102c37;
  justify-content: center;
`;

export const BackgroundO = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  align-items: center;
  background-color: #102c37;
  justify-content: center;
`;

export const FragranceText = styled.p`
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2)/ 100}px`};
  color: white;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: white;
  margin-top: 10px;
  font-family: GucciSans;
`;
 export const Row = styled.div`
 display: flex;
 flex: 1;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 margin-bottom: 30px;
`;

export const RowFrag = styled.div`
display: flex;
flex: 1;
flex-direction: row;
justify-content: space-around;
align-items: flex-start;
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => `${props.theme.width / 2}px`};
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 10px;
`;
export const TitleSmall = styled.p`
  color: white;
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2) / 100}px`};
  text-align: center;
  margin-bottom: 20px;
  font-family: GucciSans;
`;

export const DescriptionSmall = styled.p`
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2) / 120}px`};
  color: #9da6ab;
  text-align: center;
  font-family: GucciSans;
`;

export const MainTitle = styled.p`
  color: #da907a;
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2) / 50}px`};
  font-weight: bold;
  font-family: GucciSans;
`;

export const Masonry = styled.div`
  padding-horizontal: 100px;
  margin-bottom: 50px;
  margin-top: 50px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;