import logo from "./logo.svg";
import "./App.css";
import ReactDOM from "react-dom";
import React, { useRef, useState, useEffect, Suspense, Component } from "react";
import {connect} from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./services/store";
import Intro from "./Containers/Intro/IntroScreen";
import Camera from "./Containers/Camera/CameraScreen";
import HomepageScreen from "./Containers/Homepage/HomepageScreen";
import IngredientScreen from "./Containers/Ingredient/IngredientScreen";
import FragranceScreen from "./Containers/Fragrance/FragranceScreen";
import FragrancesScreen from "./Containers/Fragrances/FragrancesScreen";
import EditorialScreen from "./Containers/Editorial/EditorialScreen";
import GameScreen from "./Containers/Game/GameScreen";
import { ThemeProvider } from "styled-components";
import useWindowDimensions from "./hooks/WindowDimensions";
import { isMobile, isTablet } from "react-device-detect";
import i18n, { switchLanguage } from "./translation/i18n";
function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}
const Navigation = (props) => {
  const { height, width } = useWindowDimensions();
  const [, updateState] = useState();
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    //i18n.locale = props.language.toLowerCase();
    switchLanguage(props.language.toLowerCase(), forceUpdate);
    //updateState();
  }, [props.language])

  useEffect(() => {
    //i18n.locale = props.language.toLowerCase();
    switchLanguage(props.language.toLowerCase(), forceUpdate);
    //updateState();
  }, [])
  //const base = "/:locale(en|cn)?";
  return (
      <Switch>
        <Route exact path="/" component={Intro} />
        <Route path="/camera" component={Camera} />
        <Route path="/home" component={HomepageScreen} />
        <Route path="/ingredient" component={IngredientScreen} />
        <Route path="/fragrance" component={FragranceScreen} />
        <Route path="/fragrances" component={FragrancesScreen} />
        <Route path="/editorial" component={EditorialScreen} />
        <Route path="/game" component={GameScreen} />
      </Switch>
  );
};

const mapStateToProps = (state) => ({
  language: state.user.language
});


export default connect(mapStateToProps,{})(Navigation);
