import logo from "./logo.svg";
import "./App.css";
import ReactDOM from "react-dom";
import React, { useRef, useState, Suspense, Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./services/store";
import Intro from "./Containers/Intro/IntroScreen";
import Camera from "./Containers/Camera/CameraScreen";
import HomepageScreen from "./Containers/Homepage/HomepageScreen";
import IngredientScreen from "./Containers/Ingredient/IngredientScreen";
import FragranceScreen from "./Containers/Fragrance/FragranceScreen";
import FragrancesScreen from "./Containers/Fragrances/FragrancesScreen";
import EditorialScreen from "./Containers/Editorial/EditorialScreen";
import GameScreen from "./Containers/Game/GameScreen";
import { ThemeProvider } from "styled-components";
import useWindowDimensions from "./hooks/WindowDimensions";
import { isMobile, isTablet } from "react-device-detect";
import i18n, { switchLanguage } from "./translation/i18n";
import Navigation from "./navigation";

const App = () => {
  //persistor.purge();
  const { height, width } = useWindowDimensions();
  document.title = "Gucci Bloom";
  const theme = {
    height,
    width,
    unit: width / 10,
    hunit: height / 10,
    mobile: isMobile,
    tablet: isTablet,
  };

  console.log("Theme: ", theme);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
{/*           <Router>
            <Switch>
              <Route exact path="/" component={Intro} />
              <Route path="/camera" component={Camera} />
              <Route path="/home" component={HomepageScreen} />
              <Route path="/ingredient" component={IngredientScreen} />
              <Route path="/fragrance" component={FragranceScreen} />
              <Route path="/fragrances" component={FragrancesScreen} />
              <Route path="/editorial" component={EditorialScreen} />
              <Route path="/game" component={GameScreen} />
            </Switch>
          </Router> */}
          <Router>
          <Navigation />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
