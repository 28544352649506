import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { FragranceButton } from "./FragranceButton";
import {
  Background,
  RowFrag,
  BackButton,
  Wrapper,
  LogoBottom,
  LogoTop,
} from "../../Components";
import { Fragrances } from "../../Fragrances";
import { Ingredients } from "../../Ingredients";
import useWindowDimensions from "../../hooks/WindowDimensions";
import { useHistory, useParams, useLocation, useRouteMatch } from "react-router";

/**
 * This is an example of a container component.
 *
 * This screen displays a little help message and informations about a fake user.
 * Feel free to remove it.
 */

const FragrancesScreen = () => {
  const { height, width } = useWindowDimensions();
  const history = useHistory();
  const unit = width / 10;
  const hunit = height / 10;
  const mobile = height > width;
  let fragrance;
  let location = useLocation();
  let match = useRouteMatch();
  const navigateToFragrance = (fragrance) => {
    history.push({
      pathname: `/fragrance`,
      state: { fragrance },
    });
  };

  return (
    <Background>
      <BackButton
        onClick={() => {
          history.goBack();
        }}
      />
      <LogoTop src={require("../../assets/Images/rectangle_up.png")} />
      <LogoBottom src={require("../../assets/Images/rectangle_down.png")} />
      <img
        src={require("../../assets/Images/GUCCI_BLOOM_WHITE_LOGO.png")}
        style={{
          position: "absolute",
          width: 2 * unit,
          top: 0.035 * height,
          resizeMode: "contain",
          alignSelf: "center",
          zIndex: 30,
        }}
      />
      <Wrapper>
        <video
          width={width > height ? width : null}
          height={width >= height ? null : height}
          muted
          autoPlay
          loop
          playsInline
        >
          <source src="/Videos/water.mp4" type="video/mp4" />
        </video>
      </Wrapper>
      {!mobile ? (
        <RowFrag justify={"space-around"} zIndex={10}>
          <FragranceButton
            width={width}
            onClick={() => navigateToFragrance(Fragrances.gucciBloom)}
            //marginTop={"100px"}
            fragrance={Fragrances.gucciBloom}
            source={require("../../assets/Images/GUCCI_BLOOM.png")}
          />
          <FragranceButton
            width={width}
            onClick={() => navigateToFragrance(Fragrances.profumoFiori)}
            fragrance={Fragrances.profumoFiori}
            //marginBottom={"-100px"}
            source={require("../../assets/Images/PROFUMO_FIORI.png")}
          />
          <FragranceButton
            width={width}
            onClick={() => navigateToFragrance(Fragrances.ambrosiaFiori)}
            //marginTop={"100px"}
            fragrance={Fragrances.ambrosiaFiori}
            source={require("../../assets/Images/AMBROSIA_FIORI.png")}
          />

          <FragranceButton
            width={width}
            onClick={() => navigateToFragrance(Fragrances.nettareFiori)}
            //marginBottom={"0px"}
            fragrance={Fragrances.nettareFiori}
            source={require("../../assets/Images/NETTARE_FIORI.png")}
          />
          <FragranceButton
            width={width}
            onClick={() => navigateToFragrance(Fragrances.acquaFiori)}
            //marginBottom={"300px"}
            fragrance={Fragrances.acquaFiori}
            source={require("../../assets/Images/ACQUA_FIORI.png")}
          />
        </RowFrag>
      ) : (
        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
          <RowFrag justify={"space-around"} zIndex={10}>
            <FragranceButton
              width={width}
              onClick={() => navigateToFragrance(Fragrances.gucciBloom)}
              //marginTop={"100px"}
              fragrance={Fragrances.gucciBloom}
              source={require("../../assets/Images/GUCCI_BLOOM.png")}
            />
            <FragranceButton
              width={width}
              onClick={() => navigateToFragrance(Fragrances.profumoFiori)}
              fragrance={Fragrances.profumoFiori}
              //marginBottom={"-100px"}
              source={require("../../assets/Images/PROFUMO_FIORI.png")}
            />
          </RowFrag>
          <RowFrag justify={"space-around"} zIndex={10}>
            <FragranceButton
              width={width}
              onClick={() => navigateToFragrance(Fragrances.ambrosiaFiori)}
              //marginTop={"100px"}
              fragrance={Fragrances.ambrosiaFiori}
              source={require("../../assets/Images/AMBROSIA_FIORI.png")}
            />
          </RowFrag>
          <RowFrag justify={"space-around"} zIndex={10}>
            <FragranceButton
              width={width}
              onClick={() => navigateToFragrance(Fragrances.nettareFiori)}
              //marginBottom={"0px"}
              fragrance={Fragrances.nettareFiori}
              source={require("../../assets/Images/NETTARE_FIORI.png")}
            />
            <FragranceButton
              width={width}
              onClick={() => navigateToFragrance(Fragrances.acquaFiori)}
              //marginBottom={"300px"}
              fragrance={Fragrances.acquaFiori}
              source={require("../../assets/Images/ACQUA_FIORI.png")}
            />
          </RowFrag>
        </div>
      )}
    </Background>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(FragrancesScreen);
