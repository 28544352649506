import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  Background,
  ImageButton,
  BackButton,
  Wrapper,
  WrapperFull,
  Row,
  Header,
  Model,
  Footer,
  TitleSmall,
  DescriptionSmall,
  TextSection,
  Button,
  ButtonM,
  Masonry,
  Content,
} from "../../Components";
import { Fragrances } from "../../Fragrances";
import { Ingredients } from "../../Ingredients";
import useWindowDimensions from "../../hooks/WindowDimensions";
import { useHistory, useParams, useLocation } from "react-router";
import { ArrowDownward } from "@material-ui/icons";
import i18n from "../../translation/i18n";
import noScroll from "no-scroll";
import ReactPlayer from "react-player";
/**
 * This is an example of a coverer component.
 *
 * This screen displays a little help message and informations about a fake user.
 * Feel free to remove it.
 */

export const Description = styled.p`
font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 50 : props.theme.mobile ? props.theme.width / 30 : props.theme.width / 100 )}px`};
color: white;
text-align: center;
font-family: GucciSans;
 max-width: ${(props) => props.theme.mobile ? "90%" : "40%"};
`;

/* export const Subtitle = styled.p`
 color: #bc8476;
font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 30 : props.theme.mobile ? props.theme.width / 25 : props.theme.width / 50 )}px`};
 font-style: italic;
 font-family: GucciSans;
`; */

/* export const TitleSmall = styled.p`
 color: white;
font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 50 : props.theme.mobile ? props.theme.width / 40 : props.theme.width / 100 )}px`};
 text-align: center;
 margin-bottom: 20px;
 font-family: GucciSans;
` */

export const Subtitle = styled.p`
  color: white;
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height) / 60}px`};
 // font-style: italic;
  font-family: GucciSans;
`;
export const Title = styled.p`
 color: ${(props) => props.color || "white"};
font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 30 : props.theme.mobile ? props.theme.width / 25 : props.theme.width / 50 )}px`};
 text-align: center;
 font-family: GucciSans;
 font-weight: bold;
 margin: 0px;
`;
export const MainTitle = styled.p`
  color: #da907a;
font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 30 : props.theme.mobile ? props.theme.width / 25 : props.theme.width / 50 )}px`};
  font-weight: bold;
  font-family: GucciSans;
`;
const EditorialScreen = () => {
  const { height, width } = useWindowDimensions();
  const history = useHistory();
  const unit = width / 10;
  const widthI = width * 0.75;
  const hunit = height / 10;
  const mobile = height > width;
  useEffect(() => {
    noScroll.off();
  }, []);
  const [modal, setModal] = useState(false);
  const [initialPhoto, setInitialPhoto] = useState(0);
  const setModalVisible = () => {
    setModal(!modal);
  };
  const openGallery = (index) => {
    /*     setState({ initialPhoto: index }, () => {
      this.setModalVisible();
    }); */
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        backgroundColor: "#0c1b29",
        width: width,
      }}
    >
      <BackButton
        onClick={() => {
          history.goBack();
        }}
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItem: "center",
          flexDirection: "column",
          width: width,
        }}
      >
        <Header>
        <img
          src={require("../../assets/Images/GUCCI_BLOOM_WHITE_LOGO.png")}
          style={{
            //position: "absolute",
            width: 2 * unit,
            marginTop: 0.035 * height,
            marginBottom: 0.5 * hunit, 
            resizeMode: "contain",
            alignSelf: "center",
            zIndex: 30,
          }}
        />
        </Header>
        <Content>
          <ReactPlayer
            url={"Videos/gucci_bloom_mainN.mp4"}
            width="100%"
            controls
            height="100%"
          />
          {/*             <VideoPlayer
              videoProps={{
                ref: (v) => (this.video1 = v),
                shouldPlay: false,
                resizeMode: Video.RESIZE_MODE_CONTAIN,
                source: require("../../assets/Videos/gucci_bloom_mainN.mp4"),
              }}
              showControlsOnLoad={true}
              width={width * 0.98}
              height={height / 2.4}
              hideControlsTimerDuration={1000}
              disableSlider
              showFullscreenButton
              inFullscreen={true}
              switchToPortrait={() => this.video1.presentFullscreenPlayer()}
              playbackCallback={async (callback) => {
                if (callback.isBuffering) {
                  this.video5.pauseAsync();
                  this.video2.pauseAsync();
                  this.video3.pauseAsync();
                  this.video4.pauseAsync();
                  this.video6.pauseAsync();
                }
              }}
            /> */}

          <Title style={{marginTop: "5%"}}>{i18n.t("editorial.story")}</Title>
          <div style={{display: "flex",
        flex: 1,marginTop: "1%", flexDirection: "column", alignItems: "center"}}>
          <Description>{i18n.t("editorial.description1")}</Description>
          </div>
          {/*            <Button
              onClick={() => {
                this.scrollViewRef.scrollTo({ y: height * 0.9 });
              }}
            >
              <Icon
                name={"ios-arrow-down"}
                size={30}
                color="white"
                style={{ textAlign: "center", marginBottom: 30 }}
              />
            </Button> */}
{/*           <Button
            onClick={() => {
              //scrollToBottom();
              // scrollRef.scrollToEnd({ animated: true });
            }}
          >
            <ArrowDownward
              fontSize={"small"}
              style={{
                textAlign: "center",
                marginRight: 15,
                color: "white",
                alignSelf: "center",
              }}
            />
          </Button> */}
          <div style={{marginTop: "5%"}}>
          <Title>{i18n.t("editorial.campaign")}</Title>
          </div>
          <Description>{i18n.t("editorial.description2")}</Description>
          <Masonry>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <ButtonM
                //onClick={() => openGallery(0)}
                >
                  <div
                    style={{
                      width: widthI * 0.3,
                      height: widthI * 0.25,
                      position: "relative",
                      overflow: "hidden",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        resizeMode: "contain",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                        position: "absolute",
                      }}
                      src={require("../../assets/Editorial/edit1.jpg")}
                    />
                  </div>
                </ButtonM>
                <ButtonM
                  // style={{ margin: 5 }}
                  onClick={() => openGallery(1)}
                >
                  <img
                    style={{
                      resizeMode: "cover",
                      width: widthI * 0.3,
                      height: widthI * 0.25,
                    }}
                    src={require("../../assets/Editorial/edit2.jpg")}
                  />
                </ButtonM>
              </div>
              <ButtonM
                //style={{ marginHorizontal: 5, alignItems: "center" }}
                onClick={() => openGallery(2)}
              >
                <img
                  style={{
                    resizeMode: "cover",
                    width: widthI * 0.5,
                    height: widthI * 0.5,
                  }}
                  src={require("../../assets/Editorial/edit3.jpg")}
                />
              </ButtonM>
              <div>
                <ButtonM
                  //style={{ margin: 5 }}
                  onClick={() => openGallery(3)}
                >
                  <img
                    style={{
                      resizeMode: "cover",
                      width: widthI * 0.3,
                      height: widthI * 0.25,
                    }}
                    src={require("../../assets/Editorial/edit4.jpg")}
                  />
                </ButtonM>
                <ButtonM
                  // style={{ margin: 5 }}
                  onClick={() => openGallery(4)}
                >
                  <div
                    style={{
                      width: widthI * 0.3,
                      height: widthI * 0.25,
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{
                        resizeMode: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      src={require("../../assets/Editorial/edit5.jpg")}
                    />
                  </div>
                </ButtonM>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <ButtonM onClick={() => openGallery(5)}>
                <div
                  style={{
                    width: widthI * 0.55,
                    height: widthI * 0.4,
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      resizeMode: "cover",
                      width: "100%",
                      height: "150%",
                      top: 0,
                      left: 0,
                      position: "absolute",
                    }}
                    src={require("../../assets/Editorial/edit6.jpg")}
                  />
                </div>
              </ButtonM>
              <ButtonM onClick={() => openGallery(6)}>
                <div
                  style={{
                    width: widthI * 0.55,
                    height: widthI * 0.4,
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      resizeMode: "cover",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      position: "absolute",
                    }}
                    src={require("../../assets/Editorial/edit7.jpg")}
                  />
                </div>
              </ButtonM>
            </div>
          </Masonry>
          <Title>{i18n.t("editorial.behind")}</Title>
          <div style={{display: "flex",
        flex: 1,marginBottom: "5%", flexDirection: "column", alignItems: "center"}}>
          <Description>{i18n.t("editorial.description3")}</Description>
          </div>
          <ReactPlayer
            url={"Videos/gucci_bloomH.mp4"}
            width="100%"
            controls
            height="100%"
          />

          <div style={{ marginTop: 50 }}>
            <Title>{i18n.t("editorial.talent")}</Title>
          </div>
          <div style={{ display: "flex",flexDirection: "row", marginTop: 50 }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 5 }}>
              <ReactPlayer
                url={"Videos/angelicaN.mp4"}
                controls
                height="100%"
                width={width/2.5}
              />

              <Subtitle>{i18n.t("editorial.anjelica")}</Subtitle>
            </div>
            <div style={{ display: "flex",flexDirection: "column",alignItems: "center", margin: 5 }}>
              <ReactPlayer
                url={"Videos/florenceN.mp4"}
                controls
                height="100%"
                width={width/2.5}
              />

              <Subtitle>{i18n.t("editorial.florence")}</Subtitle>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row", margin: 50 }}>
            <div style={{ display: "flex",flexDirection: "column",alignItems: "center", margin: 5 }}>
              <ReactPlayer
                url={"Videos/jodieN.mp4"}
                controls
                height="100%"
                width={width/2.5}
              />

              <Subtitle>{i18n.t("editorial.jodie")}</Subtitle>
            </div>
            <div style={{ display: "flex",flexDirection: "column",alignItems: "center", margin: 5 }}>
              <ReactPlayer
                url={"Videos/susieN.mp4"}
                controls
                height="100%"
                width={width/2.5}
              />
              <Subtitle>{i18n.t("editorial.susie")}</Subtitle>
            </div>
          </div>
        </Content>
      </div>
      {/* <Modal
        animationType="slide"
        backdropOpacity={0.7}
        transparent={true}
        isVisible={this.state.modal}
        onBackdropPress={() => this.setModalVisible()}
        coverScreen
        style={{ margin: 0, justifyContent: "center" }}
      >
        {this.state.modal ? (
          <ViewPager
            ref={(ref) => (this.viewpager = ref)}
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
            initialPage={this.state.initialPhoto}
          >
            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              key="1"
            >
              <Image
                style={{
                  resizeMode: "contain",
                  width: width,
                }}
                source={require("../../assets/Editorial/edit1.jpg")}
              />
            </div>
            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              key="2"
            >
              <Image
                style={{
                  resizeMode: "contain",
                  width: width,
                }}
                source={require("../../assets/Editorial/edit2.jpg")}
              />
            </div>
            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              key="3"
            >
              <Image
                style={{
                  resizeMode: "contain",
                  width: width,
                }}
                source={require("../../assets/Editorial/edit3.jpg")}
              />
            </div>
            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              key="4"
            >
              <Image
                style={{
                  resizeMode: "contain",
                  width: width,
                }}
                source={require("../../assets/Editorial/edit4.jpg")}
              />
            </div>
            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              key="5"
            >
              <Image
                style={{
                  resizeMode: "contain",
                  width: width,
                }}
                source={require("../../assets/Editorial/edit5.jpg")}
              />
            </div>
            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              key="6"
            >
              <Image
                style={{
                  resizeMode: "contain",
                  width: width,
                }}
                source={require("../../assets/Editorial/edit6.jpg")}
              />
            </div>
            <div
              style={{ alignItems: "center", justifyContent: "center" }}
              key="7"
            >
              <Image
                style={{
                  resizeMode: "contain",
                  width: width,
                }}
                source={require("../../assets/Editorial/edit7.jpg")}
              />
            </div>
          </ViewPager>
        ) : null}
        <Button
          onClick={() => {
            this.setModalVisible();
          }}
          style={{ position: "absolute", top: 20, right: 20 }}
        >
          <Icon
            name={"md-close"}
            size={100}
            color={"#d0d1d1"}
            style={{ textAlign: "center" }}
          />
        </Button>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(EditorialScreen);
