import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  BackButton,
  Wrapper,
  WrapperFull,
  Row,
  Header,
  Model,
  Footer,
  Button,
} from "../../Components";
import { Fragrances } from "../../Fragrances";
import { Ingredients } from "../../Ingredients";
import useWindowDimensions from "../../hooks/WindowDimensions";
import { useHistory, useParams, useLocation } from "react-router";
import { ArrowDownward } from "@material-ui/icons";
import i18n from "../../translation/i18n";
import noScroll from "no-scroll";
import { isTablet } from "react-device-detect";

/**
 * This is an example of a container component.
 *
 * This screen displays a little help message and informations about a fake user.
 * Feel free to remove it.
 */
 export const ImageB = styled.img`
 height: ${(props) => props.theme.mobile ? `${props.theme.height / 8}px` : `${props.theme.width / 6}px`};
 width: ${(props) => props.theme.mobile ? `${props.theme.height / 8}px` : `${props.theme.width / 6}px`};
 margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
 margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)};
 margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
 margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
`;
 export const ImageButton = ({ ...props }) => {
  return (
    <Button style={{ zIndex: 10 }} onClick={props.onClick}>
      <ImageB
        src={props.source}
        marginBottom={props.marginBottom}
        marginLeft={props.marginLeft}
        marginRight={props.marginRight}
        marginTop={props.marginTop}
      />
    </Button>
  );
};

 export const Description = styled.p`
 font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 50 : props.theme.mobile ? props.theme.width / 30 : props.theme.width / 100 )}px`};
 color: white;
 text-align: center;
 font-family: GucciSans;
 max-width: 50%;
`;
export const DescriptionSmall = styled.p`
 font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 50 : props.theme.mobile ? props.theme.width / 35 : props.theme.width / 100 )}px`};
  color: white;
  text-align: center;
  font-family: GucciSans;
 max-width: 100%;
`;
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => `${props.theme.width > props.theme.height ? props.theme.width  / 2.5 : props.theme.width  / 2 }px`};
  justify-content: center;
  align-items: center;
  flex: 1;
`;
export const Subtitle = styled.p`
  color: #bc8476;
 font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 30 : props.theme.mobile ? props.theme.width / 25 : props.theme.width / 50 )}px`};
  font-style: italic;
  font-family: GucciSans;
`;

export const TitleSmall = styled.p`
  color: white;
 font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 50 : props.theme.mobile ? props.theme.width / 35 : props.theme.width / 100 )}px`};
  text-align: center;
  margin-bottom: 20px;
  font-family: GucciSans;
`
export const Title = styled.p`
  color: ${(props) => props.color || "white"};
 font-size: ${(props) => `${(props.theme.tablet ? props.theme.width / 30 : props.theme.mobile ? props.theme.width / 25 : props.theme.width / 50 )}px`};
  text-align: center;
  font-family: GucciSans;
  font-weight: bold;
  margin: 0px;
`;

const FragranceScreen = () => {
  noScroll.off();
  const { height, width } = useWindowDimensions();
  const history = useHistory();
  const unit = width / 10;
  const hunit = height / 10;
  let fragrance;
  let location = useLocation();
  const [scrollStatus, setScrollStatus] = useState(false);
  const [placeholder, setPlaceholder] = useState(true);

  const scrollRef = React.useRef(null);

  if (location.state && location.state.fragrance) {
    fragrance = location.state.fragrance;
    //IModel = MODEL[ingredient.model.charAt(0).toUpperCase() + ingredient.model.slice(1)];
  }

  const scrollToBottom = () => {
    console.log(scrollRef);
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrolled = () => {
    setScrollStatus(true);
  };

  const onMessage = (event) => {
    //Prints out data that was passed.
    setPlaceholder(false);
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <div style={{ display: "flex", flex: 1, backgroundColor: "#0c1b29", width: width, overflow: "hidden", alignItem: "center", justifyContent: "center" }}>
      <BackButton onClick={() => history.goBack()} />{" "}
      <WrapperFull>
        <video
          width={width > height ? width : null}
          height={width >= height ? null : height * 1.5}
          style={{objectFit: "fill"}}
          muted
          autoPlay
          loop
          playsInline
        >
          <source src="/Videos/water.mp4" type="video/mp4" />
        </video>
      </WrapperFull>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flex: 1, zIndex: 2, }}
        onScroll={() => scrolled()}
      >
        <img
          src={require("../../assets/Images/GUCCI_BLOOM_WHITE_LOGO.png")}
          style={{
            position: "absolute",
            width: 2 * unit,
            top: 0.035 * height,
            alignSelf: "center",
            zIndex: 2,
          }}
        />
        {placeholder && fragrance ? (
          <img
            style={{
              //position: "absolute",
              alignSelf: "center",
              //width: width / 1.5,
              height: width > height ? width / 3 : isTablet ? width / 1.2 : width * 1.1,
              resizeMode: "contain",
            }}
            src={fragrance.image}
          />
        ) : null}
        <Header>
          <Subtitle>
            {fragrance !== undefined
              ? i18n.t(`${fragrance.object}.adjectives`)
              : ""}
          </Subtitle>
          <Title>
            {fragrance !== undefined
              ? i18n.t(`${fragrance.object}.fragranceName`)
              : ""}
          </Title>
          <Title>
            {fragrance !== undefined
              ? i18n.t(`${fragrance.object}.fragranceType`)
              : ""}
          </Title>
          <Description>
            {fragrance !== undefined
              ? i18n.t(`${fragrance.object}.description`)
              : ""}
          </Description>
        </Header>
        <div style={{display: "flex",
        flex: 1,marginBottom: "5%", marginTop: "3%", flexDirection: "column", alignItems: "center"}}>
        <Button
          onClick={() => {
            scrollToBottom()
            // scrollRef.scrollToEnd({ animated: true });
          }}
        >
          <ArrowDownward
            fontSize={"small"}
            style={{
              textAlign: "center",
              marginRight: 15,
              color: "white",
              alignSelf: "center",
            }}
          />
        </Button>
        </div>
        {fragrance ? (
          <>
            <Footer hidden={scrollStatus ? 1 : 0}>
              {fragrance.ingredients.length === 1 ? (
                <Row>
                  <TextSection>
                    <TitleSmall>
                      {i18n.t(`${fragrance.ingredients[0]}.name`)}
                    </TitleSmall>
                    <DescriptionSmall>
                      {i18n.t(`${fragrance.ingredients[0]}.description2`)}
                    </DescriptionSmall>
                  </TextSection>
                  <ImageButton
                    source={Ingredients[fragrance.ingredients[0]].image}
                  />
                </Row>
              ) : (
                <>
                  <Row ref={scrollRef}>
                    <TextSection>
                      <TitleSmall>
                        {i18n.t(`${fragrance.ingredients[0]}.name`)}
                      </TitleSmall>
                      <DescriptionSmall>
                        {i18n.t(`${fragrance.ingredients[0]}.description2`)}
                      </DescriptionSmall>
                    </TextSection>
                    <ImageButton
                      source={Ingredients[fragrance.ingredients[0]].image}
                    />
                  </Row>
                  <Row >
                    <ImageButton
                      source={Ingredients[fragrance.ingredients[1]].image}
                    />
                    <TextSection>
                      <TitleSmall>
                        {i18n.t(`${fragrance.ingredients[1]}.name`)}
                      </TitleSmall>
                      <DescriptionSmall>
                        {i18n.t(`${fragrance.ingredients[1]}.description`)}
                      </DescriptionSmall>
                    </TextSection>
                  </Row>
                  <Row >
                    <TextSection>
                      <TitleSmall>
                        {i18n.t(`${fragrance.ingredients[2]}.name`)}
                      </TitleSmall>
                      <DescriptionSmall>
                        {i18n.t(`${fragrance.ingredients[2]}.description`)}
                      </DescriptionSmall>
                    </TextSection>
                    <ImageButton
                      source={Ingredients[fragrance.ingredients[2]].image}
                    />
                  </Row>
                </>
              )}
            </Footer>
          </>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(FragranceScreen);
