import React, { Component } from 'react';
import styled from "styled-components";
import i18n from "../../translation/i18n";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const Circle = styled.div`
  background-color: #ffffff;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  align-self: center;
  position: absolute;
  left: 10px;
  top: 10px;
`

const CircleBigger = styled.div`
  border: 1px solid #ffffff;
  width: 110px;
  height: 110px;
  border-radius: 55px;
  align-self: center;
  position: absolute; 
`
const Button = styled.a`
display: flex;
justify-content: center;
align-items: center;
width: 200px;
height: 50px;
border-radius: 30px;
z-index: 10;
background-color: transparent;
border: 1px solid white;
align-self: center;
outline: none;
cursor: pointer;
`
 const ButtonText = styled.p`
 font-family: GucciSans;
 color: white;
 text-align: center;
 `

export const Shutter = (props) => {
  return (
    <Container onClick={props.onClick} style={props.style}>
      <CircleBigger>
        <Circle />
      </CircleBigger>
    </Container>

  )
};

export const ButtonPictures = (props) => {
  return (
    <Button download="gucciphoto.png" href={props.href} onClick={props.onClick} style={props.style}>
      <ButtonText>
      {i18n.t("getPicture")}
      </ButtonText>
    </Button>
  )
}