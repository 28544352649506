export default {
    back: '返回',
    skip: '跳过',
    next: '下一个',
    menu: '菜单栏',
    intro: '只有完全绽放的花朵，才能散发最迷人的香气',
    clickIngredient: '点击发现更多',
    getPicture: '获取你的图片',
    loadingPicture: '你的图片正在加载',
    downloadPicture: '在此下载你的图片',
    playGame: '畅玩神奇迷宫',
    discoverFragrance: '了解此款香水',
    shareStories: '分享你的故事',
    exploreCampaign: '探索广告大片',
    rotate: '进一步探索成分故事',
    charging: '充电中...',
    associatedFragrance: '发现相关香水',
    associatedFragrances: '了解此系列',
    new: '新品',
    ylang: {
        name: '依兰',
        adjectives: '明亮、欢快',
        description: "站在依兰树下，呼吸之间都能感受到被称为‘花中之花’的依兰所赋予的平和与宁静。花朵在清晨时分采摘，进行缓慢的水蒸汽蒸馏处理，使花瓣免受损伤。这种充满生命力的花，令人联想到神秘的特质，也令人渴望旅行。",  
    },
    bloomAccord: {
        name: '花悦香氛',
        subtitle: "茉莉花苞 - 使君子 - 晚香玉",
        subtitle1: '晚香玉',
        subtitleText1: "晚香玉是一种感性的花，每年在同一个位置盛开一次。这种在天黑之后才散发出妩媚香气的圣洁鲜花，在印度被称为“夜晚的女主人”。在晚香玉仍处于花苞状态时采摘，使它们在开花时充分散发迷人香气。",
        subtitleText2: "茉莉花是栽培历史最悠久的芳香花卉之一。在茉莉花的原产地——位于印度东南部的一个省份，人们把它称为‘林中月光’，以这种诗意的方式令人联想到它是‘只在夜晚盛开的花’。因此，茉莉花拥有捕捉神秘月光的力量。",
        subtitleText3: "在印度发现的使君子诠释了这一新款香水的概念和名字，因为它的花朵绽放之初是白色的，逐渐加深为粉红色，最后变为红色。这种藤本植物的花朵令人联想到略带脂粉味的柔美的花香调香水。",
        subtitle2: '茉莉花',
        subtitle3: '使君子',
        adjectives: '真实、独特',
        description: "晚香玉是一种感性的花，每年在同一个位置盛开一次。这种在天黑之后才散发出妩媚香气的圣洁鲜花，在印度被称为“夜之女王”。在晚香玉仍处于花苞状态时采摘，使它们在开花时充分散发迷人香气。茉莉花是栽培历史最悠久的芳香花卉之一。在茉莉花的原产地——印度东南部的一个省份，人们将其称为‘林中月光’，以这种诗意的方式令人联想到它是‘只在夜晚盛开的花’。因此，茉莉花拥有捕捉神秘月光的力量。在印度发现的使君子诠释了这一全新香水的概念和名字，因为它的花朵绽放之初是白色的，逐渐加深为粉红色，最后变为红色。这种藤本植物的花朵令人联想到带有脂粉香气的柔美花香调香水。",
        description2: "古驰花悦香水，颂扬女性的现代魅力和真实本色。天然茉莉花苞、晚香玉和使君子的独特香气交织相融，在肌肤上留下馥郁的白色花香。",
    },
    sandal: {
        name: '檀香木',
        adjectives: '明亮、欢快',
        description: "檀香木已有4000多年的的神圣历史，用途十分广泛。它那浓郁的黄色/金黄色油脂所散发的温暖、柔和、甜美、辛辣、木质的香膏气味，也被形容为黄油香、动物香和奶油香，在全世界广受赞誉。",  
    },
    velvety: {
        name: '鸢尾根茎',
        adjectives: '优雅、柔美',
        description: "形态优美而香气微妙的鸢尾根茎，象征着大自然的美。古希腊人和古罗马人都曾用鸢尾根茎提炼精油。这一标志性的香调，如今来自五月份盛开的鸢尾花制得的鸢尾凝脂，甚至可能比黄金还珍贵。", 
    },
    damascena: {
        name: '大马士革玫瑰',
        adjectives: '优雅、柔美',
        description: "大马士革玫瑰是极具标志性的玫瑰品种，在清晨采摘，将玫瑰的芬芳完美再现。它的香气是温暖而略微辛辣的，带着些许蜜糖般的气息。",  
    },
    rose: {
        name: '玫瑰',
        adjectives: '神秘、感性',
        description: "玫瑰是香水爱好者最爱的花香之一，因此她被称为“花中皇后”。玫瑰被认为是一种高贵而又广泛的成分，自古以来就因其细腻清新和淡雅轻盈而深受青睐。",
    },
    osmanthus: {
        name: '桂花',
        adjectives: '神秘、感性',
        description: "桂花是中国十大名花之一，象征着吉祥平和之意。桂花(Osmanthus)这个名字也有芳香之花的意思。在气候温和的地区，桂花树在秋冬两季盛开小巧的花朵。经提炼所得的桂花精油属于花果香调，带着蜜糖、木质和皮革的底香。",  
    },
    galbanum: {
        name: '绿意盎然的白松香',
        adjectives: '喜悦、活泼',
        description: "充满清新绿意的白松香是古代焚香的重要原料。埃及人将它从中东进口，视为神圣之物。从七月至九月，将白松香于茎部进行手工收割，经过精挑细选后萃取出优质的白松香树脂。",
    },
    cassis: {
        name: '黑醋栗花苞',
        adjectives: '喜悦、活泼',
        description:"黑醋栗精油来自于黑醋栗花苞。黑醋栗原产于北欧，生长在陆地高山和温带地区。黑醋栗花苞具有浓郁的果香和异域气息，同时为香水增添清新明晰的感觉。", 
    },
    profumoFiori: {
        adjectives: '明亮、欢快',
        description: "古驰花悦梦意，是对女性的欢欣颂歌。层次丰富的依兰将花悦香水的标志性香调诠释得更加富有魅力，温暖的檀香木和明亮的晒干木香则为香氛注入了一缕淡淡的清爽的奶油香气。",
        fragranceName: '花悦梦意',
        fragranceType: '香水',
    },
    gucciBloom: {
        adjectives: '真实、独特',
        description: "古驰花悦香水，颂扬女性的现代魅力。天然茉莉花苞、晚香玉和使君子的独特香气交织相融，在肌肤上留下馥郁的白色花香。",
        fragranceName: 'GUCCI BLOOM花悦',
        fragranceType:  '香水',
    },
    ambrosiaFiori: {
        adjectives: '优雅、柔美',
        description: "古驰花悦馥意，启迪女性欣然面对自我本色和真实情感。这款香水融入了稀有的鸢尾根茎和优雅的大马士革玫瑰，令花悦的标志性花香愈加馥郁。",
        fragranceName:  '花悦馥意',
        fragranceType: '香水',
    },
    nettareFiori: {
        adjectives: '神秘、感性',
        description: "古驰花悦蜜意，发掘女性神秘的内在特质以及真实、自信和打破常规的魅力。玫瑰与桂花的柔美馨香与花悦的香气融汇，呈现为迷人的西普花香调。",
        fragranceName: '花悦蜜意',
        fragranceType: '香水',
    },
    acquaFiori: {
        adjectives: '喜悦、活泼',
        description: "古驰花悦绿意，闪耀着青春和友谊的喜悦与活力。全新香调融合白松香和秀丽的黑醋栗花苞香气，与原版古驰花悦香调完美搭配，交织氤氲出纯净、活泼的感觉。",
        fragranceName: '花悦绿意',
        fragranceType: '淡香水',
    },
    editorial: {
        gucciBloom:'THE GUCCI BLOOM（花悦）',
        story:'香水故事',
        description1: '生机盎然的全新花悦梦意香水，致敬那些与古驰当代美学契合的人们，他们蕴含着无穷活力与无限可能，尽情享受生活带来的一切过程与结果，自由地展现真我。他们在生活中创造难忘的时刻、乐于分享故事，并从他们选择接触的文化、艺术和各种事物中获得启迪。',
        description2: 'Gucci Bloom花悦香水的广告大片讲述梦幻花园中四位女性的故事。Anjelica Huston、Florence Welch、Jodie Turner-Smith和Susie Cave这四位极富个人魅力的女主角依次出现，如美梦般令人心动。',
        description3: '大片中，每朵花在现实与超现实相互交织的世界里，都是一个独特的生命体，它们来自于大自然迷幻缥缈的梦境，Anjelica、Florence、Jodie和Susie分别代表四种不同的女性，她们被鲜花所簇拥。而镜头后的Floria Sigismondi，仿佛担任了女祭司的角色。连拍摄广告大片这件事，都像是在魔法世界中施展魔法一样。” 亚力山卓·米开理说',
        campaign: '广告大片',
        behind:  '幕后亮点',
        talent: '观看访谈',
        anjelica: 'Anjelica Huston',
        florence: 'Florence Welch',
        jodie: 'Jodie Turner-Smith',
        susie: 'Susie Cave'
    }
};