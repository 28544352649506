import {
    USER_SET,
    USER_LOGIN,
    SET_LANGUAGE
} from "./constants";

export const setUser = (payload) => {
    return {
        type: USER_SET,
        payload
    };
};

export const setLanguage = (payload) => {
    return {
        type: SET_LANGUAGE,
        payload
    };
};

export const userLogin = (payload) => {
    return {
        type: USER_LOGIN,
        payload
    };
};
