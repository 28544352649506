import { combineReducers } from 'redux';
import user from './user/reducer';
import image from './image/reducer';


const appReducer = combineReducers({
    user,
    image
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
