import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  BackButton,
  Background,
  Wrapper,
  Button,
  Title,
  ButtonVert,
} from "../../Components";
import { Fragrances } from "../../Fragrances";
import i18n from "../../translation/i18n";
import { useHistory, useParams, useLocation, useRouteMatch } from "react-router";
import useWindowDimensions from "../../hooks/WindowDimensions";
import * as MODEL from "../Models";

/**
 * This is an example of a container component.
 *
 * This screen displays a little help message and informations about a fake user.
 * Feel free to remove it.
 */

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const Model = styled.div`
  display: flex;
  width: ${(props) => `${props.theme.width}px`};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 130px;
  flex-direction: column;
  z-index: 5;
`;

const Subtitle = styled.p`
  color: white;
  font-size: ${(props) =>
    `${
      (props.theme.width > props.theme.height
        ? props.theme.width
        : props.theme.height) / 50
    }px`};
  font-style: italic;
  font-family: GucciSans;
`;

const Description = styled.p`
  font-size: ${(props) =>
    `${
      (props.theme.width > props.theme.height
        ? props.theme.width / 100
        : (props.theme.height) / 65)
    }px`};
  color: white;
  text-align: center;
  font-family: GucciSans;
  max-width: 1000px;
  z-index: 10;
`;

export const BackgroundI = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  align-items: center;
  background-color: #102c37;
  justify-content: center;
`;

export const BackgroundO = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  align-items: center;
  background-color: #102c37;
  justify-content: center;
`;

const FragranceText = styled.p`
  font-size: ${(props) =>
    `${
      (props.theme.width > props.theme.height
        ? props.theme.width / 100
        : (props.theme.height) / 75)
    }px`};
  color: white;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: white;
  margin-top: 10px;
  font-family: GucciSans;
`;

const IngredientScreen = () => {
  const [placeholder, setPlaceholder] = useState(true);
  const { height, width } = useWindowDimensions();
  const mobile = height > width;
  const history = useHistory();
  let match = useRouteMatch();
  let ingredient;
  let IModel;
  let location = useLocation();
  console.log("models", MODEL.Ylang);

  if (location.state.ingredient) {
    ingredient = location.state.ingredient;
    IModel =
      MODEL[
        ingredient.model.charAt(0).toUpperCase() + ingredient.model.slice(1)
      ];
  }
  console.log(ingredient, history, location);
  const unit = width / 10;
  const hunit = height / 10;

  /*   useEffect(() => {
    setIngredient(ingredient);
  }, []); */

  const navigateToFragrance = (fragrance) => {
    history.push({
      pathname: `/fragrance`,
      state: { fragrance },
    });
  };

  if (height < width) {
    return (
      <BackgroundO>
        <img
          src={require("../../assets/Images/GUCCI_BLOOM_WHITE_LOGO.png")}
          style={{
            position: "absolute",
            width: 2 * unit,
            top: 0.035 * height,
            resizeMode: "contain",
            alignSelf: "center",
            zIndex: 30,
          }}
        />
        <BackButton onClick={() => history.goBack()} />
        <Wrapper>
          <video
            width={width > height ? width : null}
            height={width >= height ? null : height}
            muted
            autoPlay
            loop
            playsInline
          >
            <source src="/Videos/water.mp4" type="video/mp4" />
          </video>
        </Wrapper>
        <Model>
          <IModel />
          <Description>
          {i18n.t('rotate')}
          </Description>

        </Model>
        <Header>

          <Subtitle>
            {ingredient !== undefined
              ? i18n.t(`${ingredient.model}.adjectives`)
              : ""}
          </Subtitle>
          <Title>
            {ingredient !== undefined
              ? i18n.t(`${ingredient.model}.name`).toUpperCase()
              : ""}
          </Title>

          {/*       {placeholder && ingredient ? (
        <img
          style={{
            position: "absolute",
            top: height / 4,
            alignSelf: "center",
            width: width / 2,
            height: height / 2.5,
            resizeMode: "contain",
          }}
          src={ingredient.image}
        />
      ) : null} */}
          {/*       <div
        style={{
          display: "flex",
          flex: 0,
          height: height / 2.5,
          backgroundColor: "transparent",
        }}
      /> */}
          <div
            style={{
              width: "80%",
              alignSelf: "center",
            }}
          >
            <Description>
              {ingredient !== undefined
                ? i18n.t(`${ingredient.model}.description`)
                : ""}
            </Description>
          </div>
          <ButtonVert
            onClick={() =>
              navigateToFragrance(Fragrances[ingredient.fragranceObject])
            }
          >
            <img
              style={{ height: height / 10, marginTop: 5 }}
              src={
                ingredient
                  ? ingredient.fragranceImage
                  : require("../../assets/Images/SHAPE_FRAG.png")
              }
            />
            <FragranceText>{i18n.t("associatedFragrance")}</FragranceText>
          </ButtonVert>
        </Header>
      </BackgroundO>
    );
  } else {
    return (
      <BackgroundI>
        <img
          src={require("../../assets/Images/GUCCI_BLOOM_WHITE_LOGO.png")}
          style={{
            width: 2 * unit,
            resizeMode: "contain",
            alignSelf: "center",
            zIndex: 30,
            marginTop: 0.035 * height, 
          }}
        />
        <BackButton onClick={() => history.goBack()} />
        <Wrapper>
          <video
            width={width > height ? width : null}
            height={width >= height ? null : height}
            muted
            autoPlay
            loop
            playsInline
          >
            <source src="/Videos/water.mp4" type="video/mp4" />
          </video>
        </Wrapper>
        <Header>
          <Subtitle>
            {ingredient !== undefined
              ? i18n.t(`${ingredient.model}.adjectives`)
              : ""}
          </Subtitle>
          <Title>
            {ingredient !== undefined
              ? i18n.t(`${ingredient.model}.name`).toUpperCase()
              : ""}
          </Title>
        </Header>
        <Model>
          <IModel />

        </Model>
        <Description>
          {i18n.t('rotate')}
          </Description>
        {/*       {placeholder && ingredient ? (
        <img
          style={{
            position: "absolute",
            top: height / 4,
            alignSelf: "center",
            width: width / 2,
            height: height / 2.5,
            resizeMode: "contain",
          }}
          src={ingredient.image}
        />
      ) : null} */}
        {/*       <div
        style={{
          display: "flex",
          flex: 0,
          height: height / 2.5,
          backgroundColor: "transparent",
        }}
      /> */}
        <Footer>
        <div
            style={{
              width: "80%",
              alignSelf: "center",
            }}
          >
          <Description>
            {ingredient !== undefined
              ? i18n.t(`${ingredient.model}.description`)
              : ""}
          </Description>
          </div>
          <ButtonVert
            onClick={() =>
              navigateToFragrance(Fragrances[ingredient.fragranceObject])
            }
          >
            <img
              style={{ height: height / 10, marginTop: 5 }}
              src={
                ingredient
                  ? ingredient.fragranceImage
                  : require("../../assets/Images/SHAPE_FRAG.png")
              }
            />
            <FragranceText>{i18n.t("associatedFragrance")}</FragranceText>
          </ButtonVert>
        </Footer>
      </BackgroundI>
    );
  }
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(IngredientScreen);
