export const Fragrances = {
    profumoFiori: {
        adjectives: 'Radiant & Cheerful',
        model: 'profumo',
        description: "Gucci Bloom Profumo di fiori is an ode to the joyful and cheerful women. The Bloom iconic accord is enriched by the brilliant facets of Ylang Ylang, the warming Sandalwood and radiant Sun-drenched Woods to add a fresh, light creamy touch to the scent.",
        fragranceName: 'PROFUMO DI FIORI',
        fragranceType: 'EAU DE PARFUM',
        image: require('./assets/Images/PROFUMO_FIORI.png'),
        ingredients: ['bloomAccord','ylang', 'sandal'],
        object: 'profumoFiori'
    },
    gucciBloom: {
        adjectives: 'Authentic & Unique',
        model: 'edp',
        description: "Gucci Bloom Eau de Parfum celebrates the spirit of the contemporary and authentic women. The rich white floral scent is a bouquet of unique ingredients composed of Jasmine buds, Tuberose and Rangoon Creeper.",
        fragranceName: 'GUCCI BLOOM',
        fragranceType: 'EAU DE PARFUM',
        image: require('./assets/Images/GUCCI_BLOOM.png'),
        ingredients: ['bloomAccord'],
        object: 'gucciBloom'
    },
    ambrosiaFiori: {
        adjectives: 'Elegant & Feminine',
        model: 'ambrosia',
        description: "Gucci Bloom Ambrosia di Fiori invites those who wear it to celebrate their true selves and the emotions they feel. The Bloom iconic bouquet, is intensified by the rare Velvety Orris and the elegant Damascena Rose.",
        fragranceName: 'AMBROSIA DI FIORI',
        fragranceType: 'EAU DE PARFUM INTENSE',
        image: require('./assets/Images/AMBROSIA_FIORI.png'),
        ingredients: ['bloomAccord','velvety', 'damascena'],
        object: 'ambrosiaFiori'
    },
    nettareFiori: {
        adjectives: 'Mysterious & Sensual',
        model: 'nettare',
        description:"Gucci Bloom Nettare di Fiori explores the mysterious and intimate character of a woman, her authenticity, confidence and unconventionality. The original Gucci Bloom scent is blended into a Chypre Floral version with feminine notes of Rose and the Osmanthus flower.",
        fragranceName: 'NETTARE DI FIORI',
        fragranceType: 'EAU DE PARFUM INTENSE',
        image: require('./assets/Images/NETTARE_FIORI.png'),
        ingredients: ['bloomAccord','rose', 'osmanthus'],
        object: 'nettareFiori'
    },
    acquaFiori: {
        adjectives: 'Joyful & Lively',
        model: 'acqua',
        description: "Intensely green and fresh, Gucci Bloom Acqua di Fiori celebrates the vitality and energy of youth and friendship. A new accord of Galbanum and dainty Cassis buds blend with the notes of the original Gucci Bloom, swirling together to create pure, spirited emotion.",
        fragranceName: 'ACQUA DI FIORI',
        fragranceType: 'EAU DE TOILETTE',
        image: require('./assets/Images/ACQUA_FIORI.png'),
        ingredients: ['bloomAccord','galbanum', 'cassis'],
        object: 'acquaFiori'
    },
}