export default {
    back: 'BACK',
    skip: 'SKIP',
    next: 'NEXT',
    menu: 'MENU',
    intro: 'ONLY A FULL BLOOM CAN GIVE OFF THE MOST ENCHANTING SCENT',
    clickIngredient: 'Click on the ingredient to discover more',
    getPicture: 'GET YOUR PICTURE',
    loadingPicture: 'YOUR PICTURE IS LOADING',
    downloadPicture: 'DOWNLOAD YOUR PICTURE HERE',
    playGame: 'PLAY THE MAGIC LABIRINTH',
    discoverFragrance: 'DISCOVER THE FRAGRANCE',
    shareStories: 'SHARE YOUR STORIES',
    exploreCampaign: 'EXPLORE THE CAMPAIGN UNIVERSE',
    rotate: 'Turn to discover the ingredient',
    charging: 'Charging...',
    associatedFragrance: 'DISCOVER THE ASSOCIATED FRAGRANCE',
    associatedFragrances: 'DISCOVER THE RANGE',
    new: 'NEW',
    ylang: {
        name: 'Ylang Ylang',
        adjectives: 'Radiant & Cheerful',
        description: "To stand under an ylang ylang tree is to inhale peace and serenity from the bloom known as the “flower of flowers.” Flowers are picked early morning for slow water distillation, so petals aren't damaged. This flower of life evokes a mysterious character and invites to travel.", 
    },
    bloomAccord: {
        name: 'Bloom Accord:',
        subtitle: "JASMINE BUD - RANGOON CREEPER - TUBEROSE",
        subtitle1: 'TUBEROSE',
        subtitleText1: "Tuberose is a sensual flower and bloom once a year in the same place. Only releasing its sensual scent after dark, this sacred flower is known as the ‘Mistress of the Night'in India. The tuberose are still in bud when picked, so that they can give off their full scent as they open.",
        subtitleText2: "Jasmine is one of the oldest scented flowers cultivated by humans. Southeast of India is its land of origin, in a province called ' Moonlight of the grove ' to evoke in a poetic way “its flowers open only at night”. Hence,Jasmine has the power to capture  moonlight's mysteries.",
        subtitleText3: "Discovered in South India, the Rangoon Creeper embodies the new scent’s concept and name, as it changes color from white, gradually darkens to pink then finally to red when it blooms. The vine’s flowers are redolent with a slightly powdery feminine floral perfume.",
        subtitle2: 'JASMINE',
        subtitle3: 'RANGOON CREEPER',
        adjectives: 'Authentic & Unique',
        description: "Tuberose is a sensual flower and bloom once a year in the same place. Only releasing its sensual scent after dark, this sacred flower is known as the ‘Mistress of the Night'in India. The tuberose are still in bud when picked, so that they can give off their full scent as they open. Jasmine is one of the oldest scented flowers cultivated by humans. Southeast of India is its land of origin, in a province called ' Moonlight of the grove ' to evoke in a poetic way “its flowers open only at night”. Hence,Jasmine has the power to capture  moonlight's mysteries. Discovered in South India, the Rangoon Creeper embodies the new scent’s concept and name, as it changes color from white, gradually darkens to pink then finally to red when it blooms. The vine’s flowers are redolent with a slightly powdery feminine floral perfume.",
        description2: "Gucci Bloom Eau de Parfum celebrates the spirit of the contemporary and authentic women. The rich white floral scent is a bouquet of unique ingredients composed of Jasmine buds, Tuberose and Rangoon Creeper.",
    },
    sandal: {
        name: 'Sandal Wood',
        adjectives: 'Radiant & Cheerful',
        description: "For millennia, over 4,000 years, Sandalwood tree has had a sacred history and its uses are many. With a warm, soft and sweet, woody, spicy, balsamic scent that is also described as buttery, animalic and creamy, the thick yellow/golden oil is highly prized around the world.", 
    },
    velvety: {
        name: 'Velvety Orris',
        adjectives: 'Elegant & Feminine',
        description:"With its beauty and subtlety of its notes, velvety Orris is the symbol of the nature’s beauty. Ancient Greeks and Romans used it as an essential oil; today this iconic fragrance note—derived from resin of the flower which blooms in May—can be more valuable than gold.", 
    },
    damascena: {
        name: 'Damascena Rose',
        adjectives: 'Elegant & Feminine',
        description: "The Damascena Rose is a truly iconic breed of rose, harvested early morning to give the powerful rendition of the flower. It has a warm, slightly spicy scent with honeylike tones.", 
    },
    rose: {
        name: 'Rose',
        adjectives: 'Mysterious & Sensual',
        description:"Of all flowers, Rose is one of the perfume lover's favorite, hence she was named “Queen of flowers”. Rose is considered as a noble & widespread ingredient used since ages appreciated for its delicate freshness and lightness. ",
    },
    osmanthus: {
        name: 'Osmanthus',
        adjectives: 'Mysterious & Sensual',
        description: "One of the ten famous flowers in China which is representing fertility and peace. The name Osmanthus means fragrant flower. Tiny flowers bloom in fall and winter in mild climates. The obtained absolute is floral fruity with honey, woody, and leathery undertones.", 
    },
    galbanum: {
        name: 'Green Galbanum',
        adjectives: 'Joyful & Lively',
        description: "Intensely green and fresh, Galbanum was an important component of early incense. It was sacred to the Egyptians who imported it from the Middle East. Stems are harvested by hand from July to September and meticulously selected to have a qualitative gum/resin.",
    },
    cassis: {
        name: 'Cassis Buds',
        adjectives: 'Joyful & Lively',
        description:"Black currant (Cassis) absolute comes from the buds. Originating in Northern Europe, blackcurrant grows on high land mountains and in temperate climates. Cassis buds have an intensely fruity and exotic smell while adding freshness and clarity to a perfume.", 
    },
    profumoFiori: {
        adjectives: 'Radiant & Cheerful',
        description: "Gucci Bloom Profumo di fiori is an ode to the joyful and cheerful women. The Bloom iconic accord is enriched by the brilliant facets of Ylang Ylang, the warming Sandalwood and radiant Sun-drenched Woods to add a fresh, light creamy touch to the scent.",
        fragranceName: 'PROFUMO DI FIORI',
        fragranceType: 'EAU DE PARFUM',
    },
    gucciBloom: {
        adjectives: 'Authentic & Unique',
        description: "Gucci Bloom Eau de Parfum celebrates the spirit of the contemporary and authentic women. The rich white floral scent is a bouquet of unique ingredients composed of Jasmine buds, Tuberose and Rangoon Creeper.",
        fragranceName: 'GUCCI BLOOM',
        fragranceType: 'EAU DE PARFUM',
    },
    ambrosiaFiori: {
        adjectives: 'Elegant & Feminine',
        description: "Gucci Bloom Ambrosia di Fiori invites those who wear it to celebrate their true selves and the emotions they feel. The Bloom iconic bouquet, is intensified by the rare Velvety Orris and the elegant Damascena Rose.",
        fragranceName: 'AMBROSIA DI FIORI',
        fragranceType: 'EAU DE PARFUM INTENSE',
    },
    nettareFiori: {
        adjectives: 'Mysterious & Sensual',
        description:"Gucci Bloom Nettare di Fiori explores the mysterious and intimate character of a woman, her authenticity, confidence and unconventionality. The original Gucci Bloom scent is blended into a Chypre Floral version with feminine notes of Rose and the Osmanthus flower.",
        fragranceName: 'NETTARE DI FIORI',
        fragranceType: 'EAU DE PARFUM INTENSE',
    },
    acquaFiori: {
        adjectives: 'Joyful & Lively',
        description: "Intensely green and fresh, Gucci Bloom Acqua di Fiori celebrates the vitality and energy of youth and friendship. A new accord of Galbanum and dainty Cassis buds blend with the notes of the original Gucci Bloom, swirling together to create pure, spirited emotion.",
        fragranceName: 'ACQUA DI FIORI',
        fragranceType: 'EAU DE TOILETTE',
    },
    editorial: {
        gucciBloom:'THE GUCCI BLOOM',
        story:'STORY',
        description1: 'Blossoming into life, the new Gucci Bloom Profumo di Fiori debuts alongside a visionary campaign set in a garden of dreams where magic blooms. Celebrating the vitality and diversity of those who identify with the House’s modern philosophy, the Gucci Bloom fragrances are for those who experience life and its fruits in order to bloom into their real selves. They live to create memorable moments, to share stories, and be inspired by the objects, culture and art they choose to surround themselves with.',
        description2: 'Set at a mystical and ancient landmark, the new Gucci Bloom fragrance campaign tells the story of four women living in a garden of dreams. The protagonists are charismatic and charming women: Anjelica Huston, Florence Welch, Jodie Turner-Smith and Susie Cave, who join each other in the dream-like sequence.',
        description3: '“In the campaign, the flowers live a life of their own in a world, let’s say, real but surreal. The dream of nature is a psychedelic dream, suspended in time, where Anjelica, Florence, Jodie and Susie embody four different ways of being a woman. They move through a ritual of flowers, with Floria Sigismondi being a sort of priestess behind the camera. Even shooting the campaign itself, was like magic in magic.” Alessandro Michele',
        campaign: 'THE CAMPAIGN',
        behind: 'BEHIND THE SCENES',
        talent: 'DISCOVER THE TALENT INTERVIEWS',
        anjelica: 'Anjelica Huston',
        florence: 'Florence Welch',
        jodie: 'Jodie Turner-Smith',
        susie: 'Susie Cave'
    }
};