import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useHistory,
  useRouteMatch,
  useLocation,
  useParams,
} from "react-router";
import styled from "styled-components";
import noScroll from "no-scroll";
import {
  BackButton,
  ImageButton,
  LogoTop,
  LogoBottom,
  Button,
  Title,
} from "../../Components";
import { useSpring, animated, config } from "react-spring";
import useWindowDimensions from "../../hooks/WindowDimensions";
import CloseIcon from "@material-ui/icons/Close";
import { Ingredients } from "../../Ingredients";
import Modal from "react-modal";
import { isMobile, isTablet } from "react-device-detect";

import i18n from "../../translation/i18n";
import { TrainRounded } from "@material-ui/icons";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  top: 0;
  left: 0;
`;

export const Background = styled.div`
  display: flex;
  flex: 1;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  align-items: center;
  background-color: #102c37;
  justify-content: center;
  overflow: hidden;
`;

const HomepageScreen = () => {
  //
  const { height, width } = useWindowDimensions();
  const unit = width / 10;
  const hunit = height / 10;
  const history = useHistory();
  let location = useLocation();
  let match = useRouteMatch();
  console.log(history, location, match);
  const [homeState, toggle] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    noScroll.on();
    console.log("this is the location", location);
    if (
      location.state &&
      location.state.from &&
      location.state.from === "ingredient"
    ) {
      toggle(false);
    } else if (
      location.state &&
      location.state.from &&
      location.state.from === "menu"
    ) {
      toggle(true);
    }

    setTimeout(() => {
      toggleCta(true);
    }, 3000);
  }, []);
  const [cta, toggleCta] = useState(false);
  const [modalIsOpen, toggleModal] = useState(false);
  const mobile = isMobile;
  console.log("isMobile:", mobile, i18n.locale);
  const tablet = isTablet;
  console.log("isTablet:", tablet);
  const floating = useSpring({
    to: async (next) => {
      while (1) {
        await next({ y: mobile ? 5 : 20 });
        await next({ y: 0 });
      }
    },
    from: { y: 0 },
    config: { duration: 3500 },
  });
  const floating1 = useSpring({
    to: async (next) => {
      while (1) {
        await next({ y: mobile ? 7 : 30, x: 5 });
        await next({ y: 0, x: 0 });
      }
    },
    from: { y: 0, x: 0 },
    config: { duration: 6000 },
  });
  const floating2 = useSpring({
    to: async (next) => {
      while (1) {
        await next({ y: mobile ? 8 : 30, x: 5 });
        await next({ y: 0, x: 0 });
      }
    },
    from: { y: 0, x: 0 },
    config: { duration: 7000 },
  });
  const floating3 = useSpring({
    to: async (next) => {
      while (1) {
        await next({ y: mobile ? 2 : 15, x: 10 });
        await next({ y: 0, x: 0 });
      }
    },
    from: { y: 0, x: 0 },
    config: { duration: 7500 },
  });
  const floating4 = useSpring({
    to: async (next) => {
      while (1) {
        await next({ y: mobile ? 2 : 15, x: 10 });
        await next({ y: 0, x: 0 });
      }
    },
    from: { y: 0, x: 0 },
    config: { duration: 8000 },
  });

  const ctaFade1 = useSpring({
    textAlign: "center",
    fontFamily: "GucciSans",
    fontWeight: "bold",
    color: "white",
    position: "absolute",
    top: isTablet ? hunit * 1.5 : mobile ? hunit * 1 : height * 0.45,
    right: mobile ? unit * 3.5 : unit * 0.5,
    width: 2 * unit,
    fontSize: (width > height ? width : height * 1.2) / 80,
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 10000 },
  });
  const ctaFade2 = useSpring({
    textAlign: "center",
    fontFamily: "GucciSans",
    fontWeight: "bold",
    color: "white",
    position: "absolute",
    bottom: mobile ? height * 0.6 : height * 0.85,
    left: unit * 4,
    fontSize: (width > height ? width : height * 1.2) / 80,
    width: 2 * unit,
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 10000 },
  });
  const ctaFade3 = useSpring({
    textAlign: "center",
    fontFamily: "GucciSans",
    fontWeight: "bold",
    color: "white",
    position: "absolute",
    bottom: mobile && !isTablet ? hunit * 1.3 : height * 0.4,
    left: unit * 1,
    fontSize: (width > height ? width : height * 1.2) / 80,
    width: 2 * unit,
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 10000 },
  });
  const ctaFade4 = useSpring({
    opacity: 1,
    textAlign: "center",
    color: "white",
    position: "absolute",
    bottom: mobile && !isTablet ? hunit * 1.3 : height * 0.4,
    right: unit * 0.8,
    fontSize: (width > height ? width : height * 1.2) / 80,
    fontFamily: "GucciSans",
    fontWeight: "bold",
    width: 2.5 * unit,
    from: { opacity: 0 },
    config: { duration: 10000 },
  });
  const ctaFade5 = useSpring({
    opacity: 1,
    textAlign: "center",
    color: "white",
    //top: isMobile ? hunit : hunit * 0.5,
    //right: unit * 2.5,
    zIndex: 10,
    fontSize: (width > height ? width : height * 1.2) / 80,
    fontFamily: "GucciSans",
    fontWeight: "bold",
    // width: 3 * unit,
    from: { opacity: 0 },
    config: { duration: 10000 },
  });

  const galbanumSpring = useSpring({
    x: homeState
      ? 0
      : isTablet
      ? width / 3
      : isMobile
      ? width / 2.5
      : width / 1.75,
    y: homeState ? 0 : isTablet ? -hunit : isMobile ? -2 * hunit : 0,
    position: "absolute",
    zIndex: 10,
    left: -width / 3.5,
    top: mobile ? height / 3 : height / 8,
    from: {
      x: homeState ? width / 1.75 : 0,
      y: homeState ? (isTablet ? -hunit : 0) : 0,
    },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const damascenaSpring = useSpring({
    x: homeState ? 0 : isTablet ? width / 5 : isMobile ? width / 10 : width / 5,
    y: homeState
      ? 0
      : isTablet
      ? -height / 2.5
      : isMobile
      ? -height / 3.2
      : -height / 4,
    zIndex: 10,
    position: "absolute",
    left: width / 1.9,
    top: mobile ? 0.45 * height : height / 3.5,
    from: {
      x: homeState ? width / 5 : 0,
      y: homeState ? (isTablet ? -height / 2.5 : -height / 4) : 0,
    },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const digitalSpring = useSpring({
    x: homeState ? 0 : -4 * unit,
    y: homeState ? 0 : -3 * unit,
    zIndex: 10,
    position: "absolute",
    left: mobile ? width / 12 : width / 8,
    top: isMobile && !isTablet ? hunit * 7 : hunit * 6,
    from: { x: homeState ? -4 * unit : 0, y: homeState ? -3 * unit : 0 },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const gameSpring = useSpring({
    x: homeState ? 0 : 6 * unit,
    y: homeState ? 0 : -3 * unit,
    zIndex: 10,
    position: "absolute",
    left: isMobile ? unit * 4 : unit * 7,
    top: isTablet ? hunit * 2 : mobile ? hunit * 1.5 : hunit,
    from: { x: homeState ? 6 * unit : 0, y: homeState ? -3 * unit : 0 },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const editSpring = useSpring({
    x: homeState ? 0 : 3 * unit,
    y: homeState ? 0 : 3 * unit,
    zIndex: 10,
    position: "absolute",
    left: isMobile && !isTablet ? unit * 6 : unit * 7,
    top: isMobile && !isTablet ? hunit * 7 : hunit * 6,
    from: { x: homeState ? 3 * unit : 0, y: homeState ? 3 * unit : 0 },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const ylangSpring = useSpring({
    x: homeState ? 0 : isTablet ? 0 : isMobile ? unit * 2 : unit,
    y: homeState ? 0 : isTablet ? -2 * hunit : -hunit,
    position: "absolute",
    zIndex: 10,
    left: width / 2.5,
    top: mobile ? height * 0.4 : height * 0.15,
    from: { x: homeState ? unit : 0, y: homeState ? -hunit : 0 },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });

  const roseSpring = useSpring({
    x: homeState
      ? 0
      : isTablet
      ? -2.3 * unit
      : isMobile
      ? -1 * unit
      : -2.3 * unit,
    y: homeState
      ? 0
      : isTablet
      ? -0.5 * hunit
      : isMobile
      ? -1.5 * hunit
      : -0.5 * hunit,
    position: "absolute",
    zIndex: 10,
    right: width / 2,
    top: mobile ? 0.45 * height : height * 0.3,
    from: { x: homeState ? -2.3 * unit : 0, y: homeState ? -0.5 * hunit : 0 },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const bloomSpring = useSpring({
    x: homeState ? 0 : mobile ? 0 : unit,
    y: homeState ? 0 : mobile ? 0 : hunit * 0.5,
    position: "absolute",
    right: width / 2.5,
    zIndex: 10,
    left: isTablet ? 0.42 * width : mobile ? 0.38 * width : null,
    top: mobile ? 0.45 * height : height * 0.3,
    from: {
      x: homeState ? (mobile ? 0 : unit) : 0,
      y: homeState ? (mobile ? 0 : hunit * 0.5) : 0,
    },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const cassisSpring = useSpring({
    x: homeState
      ? 0
      : isTablet
      ? -3 * unit
      : isMobile
      ? -3 * unit
      : -3.5 * unit,
    y: homeState
      ? 0
      : isTablet
      ? -8 * hunit
      : isMobile
      ? -4.5 * hunit
      : -5 * hunit,
    position: "absolute",
    zIndex: 10,
    right: -2 * unit,
    bottom: -2 * hunit,
    from: {
      x: homeState ? -3 * unit : 0,
      y: homeState
        ? isTablet
          ? -8 * hunit
          : mobile
          ? -5.5 * hunit
          : -5 * hunit
        : 0,
    },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const osmanthusSpring = useSpring({
    x: homeState ? 0 : isTablet ? 4 * unit : isMobile ? 4 * unit : 4 * unit,
    y: homeState ? 0 : isTablet ? hunit : isMobile ? 0.5 * hunit : 2 * hunit,
    position: "absolute",
    zIndex: 10,
    left: -3 * unit,
    bottom: 3 * hunit,
    from: {
      x: homeState ? (isTablet ? 4 * unit : mobile ? 3 * unit : 4 * unit) : 0,
      y: homeState ? (mobile ? 0.5 * hunit : 2 * hunit) : 0,
    },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const orrisSpring = useSpring({
    x: homeState ? 0 : isTablet ? 3.5 * unit : isMobile ? 2 * unit : 3 * unit,
    y: homeState ? 0 : isTablet ? 0 : isMobile ? 3 * hunit : 2 * hunit,
    zIndex: 10,
    position: "absolute",
    left: mobile ? 4 * unit : 4.5 * unit,
    top: mobile ? 5.1 * hunit : 4.5 * hunit,
    from: {
      x: homeState ? (mobile ? 3.5 * unit : 3 * unit) : 0,
      y: homeState ? (mobile ? 0 : 2 * hunit) : 0,
    },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  const sandalSpring = useSpring({
    x: homeState ? 0 : isTablet ? 1.8 * unit : isMobile ? -1.8 * unit : 0,
    y: homeState
      ? 0
      : isTablet
      ? -5 * hunit
      : isMobile
      ? -3.7 * hunit
      : -5 * hunit,
    position: "absolute",
    zIndex: 10,
    left: 3 * unit,
    bottom: -3 * hunit,
    from: {
      x: homeState ? (mobile && !isTablet ? -0.5 * unit : 0) : 0,
      y: homeState ? (mobile ? -6 * hunit : -5 * hunit) : 0,
    },
    config: { duration: 1000, mass: 5, tension: 350, friction: 40 },
  });
  console.log(homeState);
  return (
    <Background>
      <LogoTop src={require("../../assets/Images/rectangle_up.png")} />
      <LogoBottom src={require("../../assets/Images/rectangle_down.png")} />
      <Wrapper>
        <video
          width={width > height ? width : null}
          height={width >= height ? null : height}
          muted
          autoPlay
          loop
          playsInline
        >
          <source src="/Videos/water.mp4" type="video/mp4" />
        </video>
      </Wrapper>
      <BackButton
        onClick={() => {
          if (homeState) {
            history.goBack();
          } else {
            toggle(true);
          }
        }}
      />

      {!modalIsOpen ? (
        <div style={{ maxHeight: width }}>
          {" "}
          <animated.div style={galbanumSpring}>
            <animated.div style={floating}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    console.log("we navigate");
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/ingredient`,
                      state: { ingredient: Ingredients.galbanum },
                    });
                  }
                }}
                source={require("../../assets/Images/GALBANUM1.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div style={ylangSpring}>
            <animated.div style={floating1}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/ingredient`,
                      state: { ingredient: Ingredients.ylang },
                    });
                  }
                }}
                source={require("../../assets/Images/YLANG1.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div style={cassisSpring}>
            <animated.div style={floating2}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/ingredient`,
                      state: { ingredient: Ingredients.cassis },
                    });
                  }
                }}
                source={require("../../assets/Images/CASSIS_BUD1.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div style={roseSpring}>
            <animated.div style={floating}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/ingredient`,
                      state: { ingredient: Ingredients.rose },
                    });
                  }
                }}
                source={require("../../assets/Images/ROSE1.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div
            style={i18n.locale === "cn" ? digitalSpring : editSpring}
          >
            <animated.div style={floating1}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  history.replace({
                    ...location,
                    state: { from: "menu" },
                  });
                  history.push(`/editorial`);
                  /*               if (homeState) {
                toggle(false);
              } */
                }}
                source={require("../../assets/Images/EDITO.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div style={osmanthusSpring}>
            <animated.div style={floating2}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/ingredient`,
                      state: { ingredient: Ingredients.osmanthus },
                    });
                  }
                }}
                source={require("../../assets/Images/OSMANTHUS1.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div style={sandalSpring}>
            <animated.div style={floating4}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/ingredient`,
                      state: { ingredient: Ingredients.sandal },
                    });
                  }
                }}
                source={require("../../assets/Images/SANDAL1.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div style={orrisSpring}>
            <animated.div style={floating3}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/ingredient`,
                      state: { ingredient: Ingredients.velvety },
                    });
                  }
                }}
                source={require("../../assets/Images/VELVETY_ORRIS1.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div style={gameSpring}>
            <animated.div style={floating2}>
              <ImageButton
                onClick={() => {
                  history.replace({
                    ...location,
                    state: { from: "menu" },
                  });
                  history.push(`/game`);
                }}
                source={require("../../assets/Images/GAMING.png")}
              />
            </animated.div>
          </animated.div>
          {i18n.locale === "cn" ? null : (
            <animated.div style={digitalSpring}>
              <animated.div style={floating1}>
                <ImageButton
                  onClick={() => {
                    toggleModal(true);
                  }}
                  source={require("../../assets/Images/DIGITAL_ROOM.png")}
                />
              </animated.div>
            </animated.div>
          )}
          <animated.div style={damascenaSpring}>
            <animated.div style={floating}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/ingredient`,
                      state: { ingredient: Ingredients.damascena },
                    });
                  }
                }}
                source={require("../../assets/Images/DAMASCENA1.png")}
              />
            </animated.div>
          </animated.div>
          <animated.div style={bloomSpring}>
            <animated.div style={floating2}>
              <ImageButton
                onClick={() => {
                  console.log(homeState);
                  if (homeState) {
                    toggle(false);
                  } else {
                    history.replace({
                      ...location,
                      state: { from: "ingredient" },
                    });
                    history.push({
                      pathname: `/fragrances`,
                    });
                  }
                }}
                source={require("../../assets/Images/BLOOM_ACCORD1.png")}
              />
            </animated.div>
          </animated.div>
          {cta && homeState ? (
            <animated.span style={ctaFade1}>{i18n.t("playGame")}</animated.span>
          ) : null}
          {cta && homeState ? (
            <animated.span style={ctaFade2}>
              {i18n.t("discoverFragrance")}
            </animated.span>
          ) : null}
          {cta && homeState && i18n.locale !== "cn" ? (
            <animated.span style={ctaFade3}>
              {i18n.t("shareStories")}
            </animated.span>
          ) : null}
          {cta && homeState ? (
            <animated.span style={i18n.locale === "cn" ? ctaFade3 : ctaFade4}>
              {i18n.t("exploreCampaign")}
            </animated.span>
          ) : null}
          {cta && !homeState ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                width: width,
                left: 0,
                height: isMobile ? 2 * hunit : hunit,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <animated.span style={ctaFade5}>
                {i18n.t("clickIngredient")}
              </animated.span>
            </div>
          ) : null}
        </div>
      ) : null}
      <Modal
        style={{
          content: {
            backgroundColor: "#0c1b29",
            border: "none",
          },
          overlay: {
            zIndex: 1000,
            backgroundColor: "transparent",
            margin: unit * 0.5,
            marginTop: hunit * 2,
            marginBottom: hunit * 2,
          },
        }}
        isOpen={modalIsOpen}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
          key="1"
        >
          <div
            style={{
              position: "absolute",
              top: "20%",
              width: mobile ? "90%" : "50%",
              alignSelf: "center",
              marginBottom: hunit * 0.5,
            }}
          >
            <Title color={"white"}>
              {
                "Try the Gucci Bloom Beauty Filter available on Instagram and share your stories with your friends"
              }
            </Title>
          </div>
          <Button
            onClick={() => {
              var a = document.createElement("a"); //Create <a>
              a.href = "https://www.instagram.com/ar/616361745931246/"; //Image Base64 Goes here
              a.target = "_blank";
              a.click();
            }}
          >
            <img
              src={require("../../assets/Images/instaLogo.png")}
              style={{
                width: width > height ? unit : hunit,
                height: width > height ? unit : hunit,
                resizeMode: "contain",
                alignSelf: "center",
                marginBottom: hunit * 0.5,
              }}
            />
          </Button>

          {/*           <img
            src={require("../../assets/Images/ig-filter-white.png")}
            style={{
              width: 4 * unit,
              resizeMode: "contain",
              alignSelf: "center",
            }}
          /> */}
        </div>
        <Button
          onClick={() => {
            toggleModal(false);
          }}
          style={{ position: "absolute", top: hunit * 0.1, right: unit * 0.1 }}
        >
          <CloseIcon
            fontSize={"large"}
            style={{
              textAlign: "center",
              color: "#d0d1d1",
              alignSelf: "center",
            }}
          />
        </Button>
      </Modal>
    </Background>
  );
};

const mapStateToProps = (state) => ({
  imageUrl: state.image.imageUrl,
});

export default connect(mapStateToProps, null)(HomepageScreen);
