import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactPlayer from "react-player";
import useWindowDimensions from "../../hooks/WindowDimensions";
import { useHistory, useRouteMatch } from "react-router-dom";
import { WrapperCursor } from "../../Components";
import ReactFlagsSelect from "react-flags-select";
import { Pulse } from "better-react-spinkit";
import i18n, { switchLanguage } from "../../translation/i18n";
import {setLanguage} from "../../services/user/actions"
import Modal from "react-modal";

/**
 * This is an example of a container component.
 *
 * This screen displays a little help message and informations about a fake user.
 * Feel free to remove it.
 */

const Logo = styled.img`
  position: absolute;
  resize-mode: contain;
  align-self: center;
  z-index: 30;
  width: ${(props) =>
    props.theme.mobile
      ? `${props.theme.hunit * 2}px`
      : `${props.theme.unit * 2}px`};
  top: ${(props) =>
    `${
      props.theme.width > props.theme.height
        ? props.theme.unit * 0.5
        : props.theme.unit * 2
    }px`}; ;
`;
/* const LargeSpinner = styled(Spinner)`
  transform: scale(2);
`; */

export const Button = styled.button`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-self: center;
  text-align: center;
  margin: 0px;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  z-index: 5;
`;
export const PButton = styled.button`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  z-index: 5;
  width: 40%;
  position: absolute;
  top: 45%;
`;
export const Title = styled.p`
  color: ${(props) => props.color || "white"};
  font-size: ${(props) =>
    `${
      (props.theme.width > props.theme.height
        ? props.theme.width
        : props.theme.height) / 50
    }px`};
  text-align: center;
  font-family: GucciSans;
  font-weight: bold;
  margin: 0px;
  z-index: 5;
`;

const Background = styled.div`
  display: flex;
  flex: 1;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  align-items: center;
  background-color: #102c37;
  justify-content: center;
`;

/* const Wrapper =styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  top: 0;
  left: 0;
`;
 */
const cred = {
  username: "guccibloom",
  password: "273705"
}

const Intro = (props) => {
  const { height, width } = useWindowDimensions();
  const [playing, setPlaying] = useState(false);
  const [cta, setCta] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState(props.language);
  const [modal, setModal] = useState(false);
  const [, updateState] = useState();
  const mobile = height > width;
/*   useEffect(() => {
    setSelected(props.language);
  }, []) */
  let history = useHistory();
  let match = useRouteMatch();
  let videoRef = React.useRef(null);
  const onValidate = () => {
    if (cred.username === username && cred.password === password) {
      setModal(false);
    }
  }
  const handleChangeUser = (event) => {
    setUsername(event.target.value);
  }
  const handleChangePass = (event) => {
    setPassword(event.target.value);
  }
  /*   constructor(props) {
    super(props);
    this.state = {
      cta: true,
      country: "US",
      visible: false,
      playing: false
    };
  } */
  return (
    <Background>
      <Logo src={require("../../assets/Images/GUCCI_BLOOM_WHITE_LOGO.png")} />
      <WrapperCursor
        onClick={() => {
          videoRef.current.play();
          setCta(false);
        }}
      >
        <video
          ref={videoRef}
          width={width > height ? width : null}
          height={width >= height ? null : height}
          muted
          //autoPlay
          playsInline
          onEnded={() => {
            history.push(`/camera`);
          }}
        >
          <source src="/Videos/intro1.mp4" type="video/mp4" />
        </video>
      </WrapperCursor>
      <div style={{ position: "absolute", top: 10, left: 10 }}>
        <ReactFlagsSelect
          countries={["GB", "CN"]}
          customLabels={{ GB: "English", CN: "Chinese" }}
          placeholder="Select Language"
          selected={selected}
          onSelect={(code) => {
            switchLanguage(code.toLowerCase(), updateState);
            props.setLanguage(code);
            setSelected(code);
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "20%",
          width: mobile ? "60%" : "30%",
          alignSelf: "center",
        }}
      >
        <Button
          onClick={() => {
            videoRef.current.play();
            setCta(false);
          }}
        >
          <Title>{i18n.t("intro")}</Title>
        </Button>
      </div>
      {cta ? (
        <PButton
          onClick={() => {
            videoRef.current.play();
            setCta(false);
          }}
        >
          <Pulse size={50} duration={"3s"} color={"white"} />
        </PButton>
      ) : null}
      <Modal
        style={{
          content: {
            backgroundColor: "white",
            border: "none",
            display: "flex",
            justifyContent: "center",
          },
          overlay: {
            zIndex: 1000,
            backgroundColor: "transparent",
          },
        }}
        isOpen={modal}
      >
        <div style={{display: "flex", flex: 1, width: 200, flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <label for="fname">Username</label>
        <input style={{fontSize: "initial"}} type="text" id="fname" name="fname" value={username} onChange={handleChangeUser}/>
        <label for="lname">Password</label>
        <input style={{fontSize: "initial"}} type="password" id="lname" name="lname" value={password} onChange={handleChangePass} />
        <button onClick={onValidate} style={{marginTop: 10}}>
          Validate
        </button>
        </div>
      </Modal>
    </Background>
  );
};

const mapStateToProps = (state) => ({
  imageUrl: state.image.imageUrl,
  language: state.user.language
});

export default connect(mapStateToProps, {setLanguage})(Intro);
