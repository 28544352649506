import I18n from "i18n-js";

import gb from "./gb";
import fr from "./fr";
import cn from "./cn";
import {store} from "../services/store";

export const switchLanguage = (lang, updateState) => {
  I18n.locale = lang;
  updateState();
  I18n.fallbacks = false;
};
//export const t = (name, params = {}) => I18n.t(name, params);

I18n.translations = {
  gb,
  fr,
  cn
};

export default I18n;
