import React from "react";
import styled from "styled-components";
import {ImageB, Subtitle, Button} from "../../Components";
import i18n from "../../translation/i18n";


const Container = styled(Button)`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width * 1.5};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 0)};
  left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
  right: ${(props) => (props.marginRight ? props.marginRight : 0)};
  top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
  align-items: center;
`;

export const Title = styled.p`
  color: ${(props) => props.color || "white"};
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2) / 80}px`};
  text-align: center;
  font-family: GucciSans;
  font-weight: bold;
  margin: 0px;
`;

export const SubtitleAdj = styled.p`
  color: #c58273;
  font-size: ${(props) => `${(props.theme.width > props.theme.height ? props.theme.width : props.theme.height * 1.2) / 90}px`};
  font-style: italic;
  font-family: GucciSans;
`;

export const FragranceButton = ({ ...props }) => (
  <Container width={props.width / 3.5} {...props} onClick={props.onClick  }>
    <ImageB width={props.width / 3.5} src={props.source} />
{/*     {props.fragrance.model === "profumo" ? (
      <Title>{i18n.t('new')}</Title>
    ) : null} */}
    <SubtitleAdj>{(i18n.t(`${props.fragrance.object}.adjectives`)).toUpperCase()}</SubtitleAdj>
    <Title>{i18n.t(`${props.fragrance.object}.fragranceName`)}</Title>
    <Title>{i18n.t(`${props.fragrance.object}.fragranceType`)}</Title>
  </Container>
);
