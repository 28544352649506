
import i18n from "./translation/i18n";

export const Ingredients = {
    ylang: {
        name: 'Ylang Ylang',
        adjectives: 'Radiant & Cheerful',
        model: 'ylang',
        description: "To stand under an ylang ylang tree is to inhale peace and serenity from the bloom known as the “flower of flowers.” Flowers are picked early morning for slow water distillation, so petals aren't damaged. This flower of life evokes a mysterious character and invites to travel.", 
        fragranceName: 'PROFUMO DI FIORI',
        fragranceType: 'EAU DE PARFUM',
        fragranceImage: require('./assets/Images/PROFUMO_FIORI.png'),
        fragranceObject: 'profumoFiori',
        image: require('./assets/Images/YLANG1.png')
    },
    bloomAccord: {
        name: 'Bloom Accord:',
        adjectives: 'Authentic & Unique',
        model: 'bloom',
        description: "Tuberose is a sensual flower and bloom once a year in the same place. Only releasing its sensual scent after dark, this sacred flower is known as the ‘Mistress of the Night'in India. The tuberose are still in bud when picked, so that they can give off their full scent as they open. Jasmine is one of the oldest scented flowers cultivated by humans. Southeast of India is its land of origin, in a province called ' Moonlight of the grove ' to evoke in a poetic way “its flowers open only at night”. Hence,Jasmine has the power to capture  moonlight's mysteries. Discovered in South India, the Rangoon Creeper embodies the new scent’s concept and name, as it changes color from white, gradually darkens to pink then finally to red when it blooms. The vine’s flowers are redolent with a slightly powdery feminine floral perfume.",
        description2: "Gucci Bloom Eau de Parfum celebrates the spirit of the contemporary and authentic women. The rich white floral scent is a bouquet of unique ingredients composed of Jasmine buds, Tuberose and Rangoon Creeper.",
        fragranceName: 'GUCCI BLOOM',
        fragranceObject: 'gucciBloom',
        fragranceType: 'EAU DE PARFUM',
        image: require('./assets/Images/BLOOM_ACCORD1.png'),
    },
    sandal: {
        name: 'Sandal Wood',
        adjectives: 'Radiant & Cheerful',
        model: 'sandal',
        description: "For millennia, over 4,000 years, Sandalwood tree has had a sacred history and its uses are many. With a warm, soft and sweet, woody, spicy, balsamic scent that is also described as buttery, animalic and creamy, the thick yellow/golden oil is highly prized around the world.", 
        fragranceName: 'PROFUMO DI FIORI',
        fragranceObject: 'profumoFiori',
        fragranceType: 'EAU DE PARFUM',
        fragranceImage: require('./assets/Images/PROFUMO_FIORI.png'),
        image: require('./assets/Images/SANDAL1.png'),
    },
    velvety: {
        name: 'Velvety Orris',
        adjectives: 'Elegant & Feminine',
        model: 'velvety',
        description:"With its beauty and subtlety of its notes, velvety Orris is the symbol of the nature’s beauty. Ancient Greeks and Romans used it as an essential oil; today this iconic fragrance note—derived from resin of the flower which blooms in May—can be more valuable than gold.", 
        fragranceName: 'AMBROSIA DI FIORI',
        fragranceObject: 'ambrosiaFiori',
        fragranceType: 'EAU DE PARFUM INTENSE',
        fragranceImage: require('./assets/Images/AMBROSIA_FIORI.png'),
        image: require('./assets/Images/VELVETY_ORRIS1.png'),
    },
    damascena: {
        name: 'Damascena Rose',
        adjectives: 'Elegant & Feminine',
        model: 'damascena',
        description: "The Damascena Rose is a truly iconic breed of rose, harvested early morning to give the powerful rendition of the flower. It has a warm, slightly spicy scent with honeylike tones.", 
        fragranceName: 'AMBROSIA DI FIORI',
        fragranceObject: 'ambrosiaFiori',
        fragranceImage: require('./assets/Images/AMBROSIA_FIORI.png'),
        fragranceType: 'EAU DE PARFUM INTENSE',
        image: require('./assets/Images/DAMASCENA1.png'),
    },
    rose: {
        name: 'Rose',
        adjectives: 'Mysterious & Sensual',
        model: 'rose',
        description:"Of all flowers, Rose is one of the perfume lover's favorite, hence she was named “Queen of flowers”. Rose is considered as a noble & widespread ingredient used since ages appreciated for its delicate freshness and lightness. ",
        fragranceName: 'NETTARE DI FIORI',
        fragranceObject: 'nettareFiori',
        fragranceType: 'EAU DE PARFUM INTENSE',
        fragranceImage: require('./assets/Images/NETTARE_FIORI.png'),
        image: require('./assets/Images/ROSE1.png'),
    },
    osmanthus: {
        name: 'Osmanthus',
        adjectives: 'Mysterious & Sensual',
        model: 'osmanthus',
        description: "One of the ten famous flowers in China which is representing fertility and peace. The name Osmanthus means fragrant flower. Tiny flowers bloom in fall and winter in mild climates. The obtained absolute is floral fruity with honey, woody, and leathery undertones.", 
        fragranceName: 'NETTARE DI FIORI',
        fragranceObject: 'nettareFiori',
        fragranceType: 'EAU DE PARFUM INTENSE',
        fragranceImage: require('./assets/Images/NETTARE_FIORI.png'),
        image: require('./assets/Images/OSMANTHUS1.png'),
    },
    galbanum: {
        name: 'Green Galbanum',
        adjectives: 'Joyful & Lively',
        model: 'galbanum',
        description: "Intensely green and fresh, Galbanum was an important component of early incense. It was sacred to the Egyptians who imported it from the Middle East. Stems are harvested by hand from July to September and meticulously selected to have a qualitative gum/resin.",
        fragranceName: 'ACQUA DI FIORI',
        fragranceObject: 'acquaFiori',
        fragranceImage: require('./assets/Images/ACQUA_FIORI.png'),
        fragranceType: 'EAU DE TOILETTE',
        image: require('./assets/Images/GALBANUM1.png'),
    },
    cassis: {
        name: 'Cassis Buds',
        adjectives: 'Joyful & Lively',
        model: 'cassis',
        description:"Black currant (Cassis) absolute comes from the buds. Originating in Northern Europe, blackcurrant grows on high land mountains and in temperate climates. Cassis buds have an intensely fruity and exotic smell while adding freshness and clarity to a perfume.", 
        fragranceName: 'ACQUA DI FIORI',
        fragranceObject: 'acquaFiori',
        fragranceType: 'EAU DE TOILETTE',
        fragranceImage: require('./assets/Images/ACQUA_FIORI.png'),
        image: require('./assets/Images/CASSIS_BUD1.png'),
    }
}