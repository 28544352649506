import ReactDOM from "react-dom";
import React, { useRef, useState, Suspense, Component } from "react";
import {
  Canvas,
  useFrame,
  useLoader,
  useThree,
  extend,
} from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Velvety from "../../assets/velvetyDraco.gltf";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from "three";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

extend({ OrbitControls });

function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01));

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={[5, 5, 5]}
      onClick={(e) => setActive(!active)}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
    >
      <torusBufferGeometry attach="geometry" args={[10, 1, 10, 100]} />
      <meshStandardMaterial
        attach="material"
        color={"white"}
      />
    </mesh>
  );
}

function Asset({ url }) {
  var manager = new THREE.LoadingManager();
  manager.onLoad = function () {
    if (window.ReactNativeWebView) {
      setTimeout(() => {
      window.ReactNativeWebView.postMessage("charged");
      }, 1000)
    }
  };
  const gltf = useLoader(GLTFLoader, url, (loader) => {
    const dracoLoader = new DRACOLoader(manager)
    dracoLoader.setDecoderPath('/draco/gltf/')
    loader.setDRACOLoader(dracoLoader)
  })
  var box = new THREE.Box3().setFromObject(gltf.scene);
  const center = box.getCenter(new THREE.Vector3());
  gltf.scene.position.x = gltf.scene.position.x - center.x;
  gltf.scene.position.y = gltf.scene.position.y - center.y;
  gltf.scene.position.z = gltf.scene.position.z - center.z;
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage("charged");
  }
  return <primitive object={gltf.scene} dispose={null} />;
}

function YlangScene(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Set up state for the hovered and active state

  // Rotate mesh every frame, this is outside of React without overhead
  //useFrame(() => (mesh.current.rotation.y += 0.01));

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={[0.6, 0.6, 0.6]}
    >
      <Asset url={Velvety} />
    </mesh>
  );
}

function Controls(props) {
  const { camera, gl } = useThree();
  const ref = useRef();
  useFrame(() => ref.current.update());
  return (
    <orbitControls
      ref={ref}
      //target={[-40, 0, 30]}
      //target={[0, 0, 0]}
      {...props}
      args={[camera, gl.domElement]}
      enableZoom={false}
    />
  );
}

class YlangScreen extends Component {
  render() {
    return (
      <Canvas
        style={{ background: "transparent", height: "50vh" }}
        camera={{ position: [0, 300, 0] }}
        sRGB={true}
      >
        <ambientLight intensity={1} />
        {/*   <spotLight
        intensity={1}
        position={[100, 100, 100]}
      /> */}
        <Controls
          enableZoom={false}
          enablePan={false}
          enableDamping
          dampingFactor={0.2}
          rotateSpeed={-0.5}
        />
        <Suspense fallback={<Box />}>{<YlangScene />}</Suspense>
        {/*<Suspense fallback={<Box />}>{<CassisScene position={[-300, 200, 200]} />}</Suspense>
      <Suspense fallback={<Box />}>{<AquaScene position={[0, 0, 0]} />}</Suspense>
      <Suspense fallback={<Box />}>{<GalbanumScene position={[200, 200, 200]} />}</Suspense>
      <Suspense fallback={<Box />}>{<BouquetScene position={[200, -100, 200]} />}</Suspense>
      <Suspense fallback={<Box />}>{<OsmanScene position={[-300, -100, 200]} />}</Suspense>
      <Suspense fallback={<Box />}>{<YlangScene position={[600, -200, 200]} />}</Suspense>
  <Suspense fallback={<Box />}>{<VelvetScene position={[600, 200, 200]} />}</Suspense>
      <ambientLight intensity={0.9} />
      <pointLight position={[10, 10, 10]} />
  */}
      </Canvas>
    );
  }
}

export default YlangScreen;
