import React, {
  useState,
  useEffect,
  Component,
  createRef,
  Fragment,
} from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { imageUpload } from "../../services/image/actions";
import Webcam from "react-webcam";
import ReactPlayer from "react-player";
import useWindowDimensions from "../../hooks/WindowDimensions";
import html2canvas from "html2canvas";
import { useScreenshot } from "use-screenshot-hook";
import { ScreenCapture } from "react-screen-capture";
import img from "../../assets/Images/waterNoAlfa.jpg";
import sizeOf from "image-size";
import mergeImages from "merge-images";
import Resizer from "react-image-file-resizer";
import { Shutter, ButtonPictures } from "./Shutter";
import {BackButton, SkipButton} from "../../Components"; 
import { useHistory, useRouteMatch, useLocation } from "react-router";
import FileSaver from "file-saver";

/**
 * This is an example of a container component.
 *
 * This screen displays a little help message and informations about a fake user.
 * Feel free to remove it.
 */

const LogoTop = styled.img`
  position: absolute;
  max-height: ${(props) => `${props.theme.width / 5}px`};
  left: ${(props) => `0px`};
  top: 0;
  z-index: 5;
`;

const LogoBottom = styled.img`
  position: absolute;
  max-height: ${(props) => `${props.theme.width / 5}px`};
  left: ${(props) => `${props.theme.width / 5}px`};
  bottom: 0;
  z-index: 5;
`;

const TransImage = styled.img`
  height: ${(props) =>
    props.theme.width > props.theme.height ? null : props.theme.height};
  width: ${(props) =>
    props.theme.width > props.theme.height ? props.theme.width : null};
  z-index: 4;
  //opacity: 0.5;
`;

const BackImage = styled.div`
  width: ${(props) => `${props.theme.width}px`};
  height: ${(props) => `${props.theme.height}px`};
  z-index: 4;
  background-image: url(${img});
  background-position: center;
  background-size: cover;
  position: absolute;
  background-color: rgb(0, 0, 0, 0.1);
  opacity: 0.5;
`;

const Background = styled.div`
  display: flex;
  flex: 1;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  align-items: center;
  background-color: #102c37;
  justify-content: center;
  overflow: hidden;
`;
const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${(props) => `${props.theme.height}px`};
  width: ${(props) => `${props.theme.width}px`};
  top: 0;
  left: 0;
`;

const CameraScreen = () => {
  const webcamRef = React.useRef(null);
  const { height, width } = useWindowDimensions();
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [hide, setHide] = useState(false);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  //const [imagePreview, setImagePreview] = useState(null);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        imageWidth,
        imageHeight,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const handleSave = async () => {
    // capture screenshot
    const canvas = await html2canvas(document.querySelector("#preview"));
    const canvasData = canvas.toDataURL("image/png");
    var file = dataURItoBlob(canvasData);
    console.log("the image data", file);
    const imgData = await resizeFile(file);
    setImage(imgData);
    const b64 = await mergeImages(
      [{ src: imagePreview, x: -(imageWidth - width) / 2}, { src: imgData, opacity: 0.5 }],
      { width: width, height: height }
    );
    console.log(b64);
    setImage(b64);
    var blob = dataURItoBlob(b64);
    FileSaver.saveAs(blob, "Gucci.png");
/*     var a = document.createElement("a"); //Create <a>
    a.href = b64; //Image Base64 Goes here
    a.target = "_blank"
    a.download = "Image.png"; //File name Here
    a.click();  *///Downloaded file
    //setImage(canvasData);
  };

  /*  const { image, takeScreenshot } = useScreenshot(); */
  console.log("image data", image);

  const videoConstraints = {
    facingMode: "user",
   // width: width,
    height: height,
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({height});
    setImagePreview(imageSrc);
    var img = new Image();
    img.onload = function () {
      setImageHeight(img.height);
      setImageWidth(img.width);
      console.log(img.height, img.width)
    };
    img.src = imageSrc;
  }, [webcamRef]);

  console.log("size in camera", height, width, videoConstraints, match.params.locale);
  return (
    <>
    <BackButton onClick={() => {history.goBack()}} />
    <SkipButton onClick={() => {history.push({pathname: `/home`, state: { from: location.pathname }})}} />
      <Background id={"preview"}>
        {!image && !imagePreview && (
          <>
            {/*          <Wrapper>
              <video
                width={width > height ? width : null}
                height={width >= height ? null : height}
                muted
                autoPlay
                loop
                playsInline
              >
                <source src="/Videos/waterNoAlfa.mp4" type="video/mp4" />
              </video>
            </Wrapper> */}
            <Webcam
              style={{
                opacity: 0.4,
                height: height,
                //width: width / 2,
                top: 0,
                left: 0,
                alignSelf: "center",
                objectFit: 'cover',
              }}
              screenshotQuality={1}
              audio={false}
              mirrored
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
            <LogoTop src={require("../../assets/Images/rectangle_up.png")} />
            <LogoBottom
              src={require("../../assets/Images/rectangle_down.png")}
            />
            <Shutter
              style={{
                width: 100,
                height: 100,
                position: "absolute",
                bottom: 50,
                left: width / 2 - 60,
                zIndex: 100,
              }}
              onClick={capture}
            />
          </>
        )}
        {imagePreview && (
          <Fragment>
            <TransImage src={imagePreview} />
            <BackImage />
            {/*             <img
              style={{
                position: "absolute",
                height: width >= height ? null : height,
                width: width > height ? width : null,
                opacity: 1,
              }}
              src={require("../../assets/Images/waterNoAlfa.jpg")}
            /> */}

            <LogoTop src={require("../../assets/Images/rectangle_up.png")} />
            <LogoBottom
              src={require("../../assets/Images/rectangle_down.png")}
            />
          </Fragment>
        )}
{/*         {image && (
          <Fragment>
            <img
              style={{
                height: width >= height ? null : height,
                width: width > height ? width : null,
                zIndex: 4,
              }}
              src={image}
            />
          </Fragment>
        )} */}
      </Background>
      {imagePreview && !hide && (
        <div
          style={{
            position: "absolute",
            bottom: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonPictures
            onClick={() => {
              //setHide(true);
              handleSave();
            }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { imageUpload })(CameraScreen);
